import styled from 'styled-components'

/**
 * @component
 * A `styled.div` element. Please see [Card](#Card)'s for example.
 */
export default styled.div`
  background-color: ${p => p.theme.colors.borderSecondary};
  height: 2px;
  margin: 0 15px;
`
