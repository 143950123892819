import styled from 'styled-components'
import colors from 'theme/colors'
import { mobileFirstMedia as media } from 'utils/media'

export const VenueStatusPortal = styled.div`
  width: 100%;
  height: 70px;
`

export const VenueStatusWrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;

  position: ${props => (props.layoutPosition === 'top' ? 'inherit' : 'fixed')};
  transform: translate(0, 0);
  width: 100%;
  z-index: 500;

  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

  ${media.screen`
    width: 420px;
    transform: translate(-50%, 0);
    left: 50%; bottom: 20px;
  `}
`

export const VenueStatusContent = styled.div`
  height: 70px;
  display: flex;
  background-color: ${colors['black']};
  color: white;
  width: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
`

export const VenueStatusTitle = styled.div`
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 20px;

  span {
    font-weight: normal;
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }
`

export const VenueStatusDescription = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

export const VenueStatusLink = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;

  ${media.screen`
    padding-right: 20px;
  `}

  a {
    color: ${colors['primary']};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 15px;
    text-transform: uppercase;
    text-decoration: none;
  }
`

export const VenueStatusIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;

  ${media.screen`
    padding-left: 22px;
    padding-right: 22px;
  `}
`
