import React from 'react'
import PropTypes from 'prop-types'

import { getAddressString } from 'utils/address'

import { H4, Text } from 'components/Text'

const AddressDisplay = ({ address, name }) => {
  return (
    <div>
      <H4>{name}</H4>
      <Text light small>
        {getAddressString(address)}
      </Text>
    </div>
  )
}

AddressDisplay.propTypes = {
  name: PropTypes.string,
  address: PropTypes.object
}

export default AddressDisplay
