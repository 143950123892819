import PT from 'prop-types'
import styled from 'styled-components'
import { mobileFirstMedia as media } from 'utils/media'

/** A `styled.section` element. Note that it has no shadow in non-desktop viewport. */
const Card = styled.section`
  background-color: ${p => p.theme.colors.bgPrimary};
  box-shadow: ${p => p.hasMobileShadow ? '0 0 5px 0 rgba(0,0,0,0.05)' : 'none'};

  ${media.screen`
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.05);
  `}
`

Card.propTypes = {
  /** If set to true, shadow is kept on mobile viewport */
  hasMobileShadow: PT.bool
}

/** @component */
export default Card
