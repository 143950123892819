import styled from 'styled-components'
import media from 'utils/media'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  border-radius: 25px;
  background-color: #ffffff;

  max-width: 1100px;
  max-height: 600px;

  ${media.phone`
    display: block;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
  `}
  ${media.screen`
    display: block;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
  `}
  ${media.tablet`
    display: block;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
  `}
`

export const Background = styled.div`
  background-color: #f2f2f2;
  max-height: 600px;
  position: relative;
  overflow-y: auto;
  border-radius: 25px;

  ${media.tablet`
    top: -195px;
    height: auto;
    max-height: 100%;
    &.expand {
      top: 0px;
      border-radius: 0px;
    }
  `}

  ${media.screen`
    overflow-x: hidden;
    border-radius: 0px;
    height: auto;
    max-height: 100%;
  `}
`

export const ItemSection = styled.div`
  background: #f2f2f2;

  ${media.tablet`
    width: 100vw;
  `}
`

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  padding: 32px;
  gap: 32px;
  width: 500px;
  flex: none;
  order: 0;
  flex-grow: 0;

  ${media.screen`
    padding: 30px 32px;
    width: 100%;
  `}

  ${media.tablet`
    padding: 24px 32px;
    width: 100%;
  `}

  ${media.tablet`
    width: 100vw;
    width: 100%;
  `}
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${media.tablet`
    margin-top: 24px;
  `}
`

export const Section = styled.div`
  width: 48%;
`
export const Title = styled(Section)`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
`

export const Price = styled.div`
  height: 40px;
  font-size: 18px;
  font-weight: 300;
  line-height: 40px;
`

export const Name = styled.div`
  height: 28px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
`

export const Description = styled.div`
  font-size: 13px;
  color: #808080;
`

export const Divider = styled.div`
  height: ${props => props.size || 2}px;
  margin-top: ${props => props.margin || '0'}px;
  width: 100%;
  background-color: ${props => props.color || ''};
`

export const ModalButton = styled.button`
  outline: none;
  border: 0;
  height: 48px;
  width: 460px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  background-color: ${props => props.theme.colors[props.color]};
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.colors.white};

  &:disabled {
    background: ${props => props.theme.colors.lightOrange};
  }

  ${media.tablet`
    width: 100%;
    margin: 0px 15px;
  `}
`

export const PlaceHolder = styled.div`
  margin-top: 40px;

  ${media.screen`
    margin-top: 220px;
  `}
`

export const ModalContainer = styled.div`
  max-width: 1100px;
  max-height: 600px;

  display: flex;
  flex-direction: row;

  ${media.screen`
    display: block;
    max-height: none;
  `}

  ${media.phone`
    max-height: none;
  `}
  ${media.tablet`
    max-height: none;
  `}
`

export const ItemInfoContainer = styled.div`
  height: 600px;
  width: 500px;

  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;

  &.no-image {
    height: auto;
  }

  ${media.screen`
    width: 100vw;

    height: auto;
  `}

  ${media.phone`
    height: auto;
  `}
  ${media.tablet`
    height: auto;
  `}
`

export const ItemInfo = styled.div`
  background: #f2f2f2;

  max-width: 500px;
  max-height: 600px;
  width: 500px;
  height: 512px;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;

  border-radius: 12px 12px 0px 0px;
  padding-bottom: 100px;

  ${media.screen`
    max-width: 100vw;
    width: 100vw;

    max-height: none;
    height: 100vh !important;

    display: block;

    border-radius: 0px;
  `}

  &.no-image {
    height: auto;
    border-radius: 12px;
    padding-bottom: 112px;
    ::-webkit-scrollbar {
      width: 4px;
      border-radius: 0px 12px 12px 0px;
    }
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
  padding: 24px 32px;

  background: #ffffff;
  border-radius: 2px;
`

export const HeaderText = styled.div`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;

  overflow-wrap: break-word;
  word-wrap: break-word;
`

export const HeaderDescription = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  color: #999999;
`

export const ItemModifier = styled.div`
  padding: 32px 32px;
  background: #ffffff;
  display: flex;
  flex-direction: row;

  justify-content: center;

  margin-top: 24px;
  ${media.screen`
    width: 100%;
  `}
`

export const QuantityContainer = styled.div`
  padding: 16px 32px;
  background: #ffffff;
  display: flex;
  flex-direction: row;

  justify-content: center;

  margin-top: 24px;
  ${media.screen`
    width: 100%;
  `}
`

export const ButtonContainer = styled.div`
  display: flex;
  padding: 20px;

  height: 88px;
  bottom: 0px;

  background: transparent;
  box-shadow: inset 0px 1px 0px #F2F2F2;

  width: 500px;
  background: #FFFFFF;
  border-radius: 0px 0px 12px 12px;

  position: absolute;

  ${media.screen`
    max-width: 100vw;
    width: 100%;
    background: transparent;
    box-shadow: none;
  `}


  ${media.phone`
    max-width: 100vw;
    width: 100%;
    background: transparent;
  `}


  ${media.tablet`
    max-width: 100vw;
    width: 100%;
    background: transparent;
  `}
`

export const AddToCart = styled.button`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

  background: #f26722;
  color: #ffffff;

  width: 100%;
  border-radius: 8px;
  height: 48px;
`

export const NumberPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
`

export const CloseButtonContainer = styled.div`
  width: 48px;
  height: 48px;
  top: 20px;
  left: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  background: #ffffff;
  /* Level 2

  Should be used on elements which are fixed to the top of the screen, like navigation bars
  */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
`
export const HeaderPlaceHolder = styled.div`
  height: 64px;
  background: #ffffff;
  border-radius: 12px 12px 0px 0px;

  ${media.screen`
    height: 48px;
  `}

  ${media.phone`
    height: 88px;
  `}
`

export const FlexCotainer = styled.div`
  display: flex;
  flex-direction: ;
`
export const ImagePlaceHolder = styled.div`

  width: 600px;
  height: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  ${media.screen`
    width: 100vw;
    border-radius: 0px;
    height: 375px;
    display: none;
  `}

  ${media.phone`
    display: none;
  `}
  ${media.tablet`
    display: none;
  `}
`
