import React from 'react'
import styled from 'styled-components'
import { number, string, bool, array, arrayOf } from 'prop-types'
import { Link } from 'react-router-dom'
import get from 'lodash/get'

import humaniseStatus from './humaniseStatus'
import imageURL from 'utils/imageURL'
import RationalImage, { ratioPropType } from 'components.v2/RationalImage'
import slugify from 'utils/slugify'
import Text from 'components.v2/Text'

export const StatusOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
`

export const Status = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
  padding: 4px;
  background-color: #666666;
  text-decoration: none;
  line-height: 100%;
`

class VenueImage extends React.Component {
  static propTypes = {
    id: number.isRequired,
    name: string.isRequired,
    status: string,
    coverImage: string,
    coverImages: arrayOf(string),
    homeImages: arrayOf(string),
    shouldUseCoverImg: bool,
    openingHours: array,
    ratio: ratioPropType,
    hasStatus: bool,
  }

  DIMENSIONS = {
    width: 457,
    height: 200,
  }

  DEFAULT_RATIOS = {
    screen: [370, 200],
    phone: [300, 180],
  }

  state = {
    hasError: false,
    imageSrc: null,
  }

  componentDidMount = () => {
    this.loadImage()
  }

  componentWillUnmount = () => {
    this.image = null
  }

  loadImage = () => {
    const imageUrl = this.getImageUrl()
    this.image = new Image()
    this.image.src = imageUrl
    this.image.onload = () => {
      this.setState(
        {
          imageSrc: imageUrl,
        },
        () => {
          this.image = null
        },
      )
    }
    this.image.onerror = () => {
      this.setState({
        hasError: true,
      })
    }
  }

  getImagePath = () => {
    const {
      shouldUseCoverImg,
      coverImages,
      homeImages,
      coverImage,
    } = this.props
    return shouldUseCoverImg
      ? (coverImages && coverImages[0]) ||
          (homeImages && homeImages[0]) ||
          coverImage
      : (homeImages && homeImages[0]) ||
          (coverImages && coverImages[0]) ||
          coverImage
  }

  getImageSrc() {
    return this.state.hasError
      ? imageURL('images/vendor/home/default.jpg', this.DIMENSIONS)
      : this.state.imageSrc
  }

  getImageUrl() {
    const { ratio } = this.props
    return imageURL(this.getImagePath(), ratio || this.DIMENSIONS)
  }

  getHumanizedStatus() {
    const { status, openingHours, hasStatus } = this.props
    return (
      hasStatus &&
      humaniseStatus(
        status,
        openingHours,
        get(this.props, 'vendorProperty.timezone'),
      )
    )
  }

  render() {
    const { id, name, ratio, hasStatus, style, renderBadge } = this.props
    const humanisedStatus = this.getHumanizedStatus()
    return (
      <RationalImage
        as={Link}
        to={`/restaurant/${id}/${slugify(name)}`}
        src={this.getImageSrc()}
        role="img"
        aria-label={`${name}'s Cover Image`}
        title={`${name}'s Cover Image`}
        ratio={ratio || this.DEFAULT_RATIOS}
        style={style}
      >
        {renderBadge && renderBadge()}
        {hasStatus && humanisedStatus && (
          <StatusOverlay>
            <Status>
              <Text as="span" variant="tertiary" color="white" solid>
                {humanisedStatus}
              </Text>
            </Status>
          </StatusOverlay>
        )}
      </RationalImage>
    )
  }
}

export default VenueImage
