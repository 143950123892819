import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, withStateHandlers } from 'recompose'

import DesktopContainer from 'layouts/DesktopContainer'
import Icon from 'components/Icon.v2'
import CuisineSearchBar from 'common/CuisineSearchBar'
import { factory as locationSearchBarFactory } from 'common/LocationSearchBar'

const TYPES = {
  CUISINE: 'cuisine',
  LOCATION: 'location',
}

const StaticSearchBars = ({ type, handleToggleType }) => (
  <DesktopContainer>
    {
      {
        [TYPES.CUISINE]: (
          <CuisineSearchBar
            rightButton={
              <Fragment>
                <Border />
                <StyledIcon type="location" onClick={handleToggleType} />
              </Fragment>
            }
          />
        ),
        [TYPES.LOCATION]: (
          <LocationSearchBar
            rightButton={
              <Fragment>
                <Border />
                <StyledIcon type="search" onClick={handleToggleType} />
              </Fragment>
            }
          />
        ),
      }[type]
    }
  </DesktopContainer>
)

StaticSearchBars.propTypes = {
  type: PropTypes.string.isRequired,
  handleToggleType: PropTypes.func.isRequired,
}

const withStaticSearchBarsContainer = compose(
  withStateHandlers(
    { type: TYPES.CUISINE },
    {
      handleToggleType: ({ type }) => () => ({
        type: type === TYPES.CUISINE ? TYPES.LOCATION : TYPES.CUISINE,
      }),
    },
  ),
)

const StyledIcon = styled(Icon).attrs({
  stroke: 'none',
  fill: '#f47325',
})`
  height: 38px;
  width: 40px;
  font-size: 20px;
`

const Border = styled.div`
  width: 1px;
  height: 20px;
  background-color: lightgray;
`

const LocationSearchBar = locationSearchBarFactory({
  Container: x => styled(x)`
    max-width: 100%;
    border-color: transparent;
  `,
  Input: x => styled(x)`
    line-height: 40px;
  `,
  AutoCompleteContainer: x => styled(x)`
    margin-top: -1px;
    border-top: 1px solid #e1e1e1;
  `,
  LocationIcon: x => styled(x).attrs({
    fill: '#f47325',
    stroke: 'none',
  })`
    width: 40px;
  `,
  TargetIcon: x => styled(x).attrs({
    fill: '#f47325',
    stroke: 'none',
  })`
    width: 40px;
  `,
})

export default withStaticSearchBarsContainer(StaticSearchBars)
