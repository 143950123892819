import styled from 'styled-components'

import { media } from 'utils/media'
import Icon from 'components/Icon'
import IconV2 from 'components/Icon.v2'

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid transparent;
  width: 100%;
  background-color: #ffffff;
`

export const Input = styled.input`
  flex: 1;
  outline: none;
  border: none;
  padding: 0;
  font-size: 16px;
  line-height: 40px;
  font-weight: 300;
  color: #333333;
  overflow: hidden;

  ::placeholder {
    color: #999999;
  }

  ${media.screen`
    line-height: 40px;
  `}
`

export const SearchIcon = styled(IconV2).attrs({
  type: 'search',
  size: 20,
  fill: '#f47325',
  stroke: 'none',
})`
  width: 40px;
`

export const ClearButton = styled.div`
  flex: 0 0 auto;
  cursor: pointer;
`

export const CrossIcon = styled(Icon).attrs({
  name: 'fas fa-times-circle',
  color: 'grey',
  size: 12,
  width: 40,
  height: 40,
})``
