import React from 'react'
import PropTypes from 'prop-types'

import { GoogleMap as Map, Marker, InfoWindow } from 'react-google-maps'

import AddressDisplay from './AddressDisplay'

class GoogleMap extends React.Component {
  state = { isOpen: false };
  onToggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen })
  };

  render () {
    const { center, address, name } = this.props
    return (
      <Map defaultZoom={18} defaultCenter={center}>
        <Marker position={center} onClick={this.onToggleOpen}>
          {this.state.isOpen && (
            <InfoWindow onCloseClick={this.onToggleOpen}>
              <AddressDisplay name={name} address={address} />
            </InfoWindow>
          )}
        </Marker>
      </Map>
    )
  }
}

GoogleMap.propTypes = {
  name: PropTypes.string,
  address: PropTypes.object,
  center: PropTypes.object
}

export default GoogleMap
