import React from 'react'
import PropTypes from 'prop-types'
import { RadioContainer, RadioInput, RadioCircle, RadioMark } from './styled'
import {
  withState,
  compose,
  setPropTypes,
  fromRenderProps,
  withProps,
  setDisplayName
} from 'recompose'

const ctx = React.createContext()

export const RadioProvider = compose(
  setDisplayName('RadioProvider'),

  withState('selectedValue', 'selectValue', props => props.initialValue),

  setPropTypes({
    initialValue: PropTypes.string.isRequired
  })
)(
  ({ selectedValue, selectValue, children }) => (
    <ctx.Provider value={{ selectValue, selectedValue }}>
      {children}
    </ctx.Provider>
  )
)

const Radio = compose(
  setDisplayName('Radio'),

  fromRenderProps(ctx.Consumer, x => x),

  withProps(({ selectValue, value }) =>
    ({
      handleSelectValue: () => selectValue(value)
    })),

  setPropTypes({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    className: PropTypes.string,
    outline: PropTypes.bool,
    color: PropTypes.string,
    baseColor: PropTypes.string,
    size: PropTypes.number,
    selectedValue: PropTypes.string.isRequired,
    handleSelectValue: PropTypes.func.isRequired
  })
)(
  ({
    value,
    className,
    color,
    baseColor = 'darkerGrey',
    outline,
    size = 20,
    selectedValue,
    handleSelectValue,
    defaultChecked,
    ...props
  }) => {
    const isChecked = value === selectedValue
    const checkmarkColor = isChecked ? color : 'white'

    return (
      <RadioContainer className={className} size={size} color={color}>
        <RadioInput onChange={handleSelectValue} value={value} type='radio' checked={isChecked} {...props} />

        <RadioCircle isChecked={isChecked} color={checkmarkColor} size={size} baseColor={baseColor}>
          {isChecked && <RadioMark size={size / 2} color={checkmarkColor} />}
        </RadioCircle>
      </RadioContainer>
    )
  }
)

export default Radio
