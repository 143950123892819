import styled from 'styled-components'
import { mobileFirstMedia as media } from 'utils/media'

export const NewsCorpForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0;

  ${media.screen`
    min-height: 215px;
    flex-direction: row;
    align-items: center;
    padding: 0px 75px 0px 75px;
  `}

  .NewsCorp__Right {
    width: 100%;

    ${media.screen`
      width: 470px;
    `}
  }

  .NewsCorp__Left {
    flex: 1;
    padding-right: 0;
    margin-bottom: 25px;

    ${media.screen`
      padding-right: 25px;
      margin-bottom: 0px;
    `}
  }

  .NewsCorp__Text {
    text-align: center;
    ${media.screen`
      text-align: left;
    `}
  }

  .NewsCorp__Btn {
    min-width: 130px;
    color: white;
    font-size: 16px;
  }
`
