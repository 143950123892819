import styled from 'styled-components'

export const NumberPickerContainer = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 38px;
  text-align: center;

  border-radius: 25px;
`

export const Button = styled.button`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-right-width: 0;
  width: 40px;
  height: 40px;
  background-color: transparent;
  color: #f47325;

  border-radius: ${props => props.borderRadius || '0px'};

  :disabled {
    pointer-events: none;
    color: #cccccc;
  }

  :last-child {
    border-right-width: 1px;
  }
`

export const Value = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-right-width: 0;
  height: 40px;
  width: 40px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
