import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import Heading from 'components.v2/Heading'
import { mobileFirstMedia as media } from 'utils/media'

const CardHeading = ({ level = 1, left, right, children, className }) => (
  <CardHeadingContainer isJustified={left || right} className={className}>
    {left && <Heading level={level}>{left}</Heading>}
    {right && <CardHeadingRight>{right}</CardHeadingRight>}
    {!(left || right) && <Heading level={level}>{children}</Heading>}
  </CardHeadingContainer>
)

CardHeading.propTypes = {
  /** `1` for h1 tag and styles, `2` for h2, etc */
  level: PT.oneOf([1, 2]),
  /** left-aligned jsx content */
  left: PT.node,
  /** right-aligned jsx content (usually a call-to-action) */
  right: PT.node,
  /** left-aligned jsx content, ignored when `left`/`right` prop is defined */
  children: PT.node,
  className: PT.string,
}

export default CardHeading

const CardHeadingContainer = styled.div`
  display: flex;
  justify-content: ${p => (p.isJustified ? 'space-between' : 'start')};
  padding: 30px 15px 0;

  ${media.screen`
    padding: 30px;
  `}
`

const CardHeadingRight = styled.span`
  font-size: 14px;
  color: ${p => p.theme.colors.textSecondary};
`
