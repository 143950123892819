import isNode from 'detect-node'

export default duration => {
  const state = {
    latestTaskId: 0,
    start: null,
  }

  const animate = step => {
    if (isNode) return

    state.latestTaskId++
    state.start = null

    window.requestAnimationFrame(
      _step({
        taskId: state.latestTaskId,
        state,
        step,
        duration,
      }),
    )
  }

  return animate
}

const _step = stepArgs => timestamp => {
  const { state, taskId, step, duration } = stepArgs

  if (taskId !== state.latestTaskId) return

  if (!state.start) state.start = timestamp

  const progress = easeOutQuad(
    Math.min(1, (timestamp - state.start) / duration),
  )

  step(progress)

  if (progress !== 1) window.requestAnimationFrame(_step(stepArgs))
}

const easeOutQuad = t => t * (2 - t)
