import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import Checkbox from 'components/Checkbox'
import Radio from 'components/Radio'

import { getPrice, formatPrice } from 'utils/currency'

const OptionContainer = styled.label`
  cursor: pointer;
  gap: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 14px;
  font-weight: 300;
  :hover {
    border-color: ${props => props.theme.colors.grey};
  }
`

const OptionName = styled.span`
  //styleName: Web - H6;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  height: 16px;
`

const OptionPrice = styled.span`
  //styleName: Web - H6;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: right;

  color: #000000;
  margin-left: auto;
`

const Option = ({ group, name, price, id, type, value }) => {
  const priceNumber = getPrice(price)
  let priceString = `+${price}`
  if (priceNumber === 0) {
    priceString = ''
  }
  if (priceNumber < 0) {
    priceString = formatPrice(priceNumber)
  }

  const Input = type === 'RADIO' ? Radio : Checkbox
  const defaultChecked = type === 'RADIO' ? null : value.indexOf(id) > -1

  return (
    <OptionContainer>
      <Input
        id={id}
        name={group}
        value={String(id)}
        size={16}
        color="orange"
        defaultChecked={defaultChecked}
      />
      <OptionName>{name}</OptionName>
      {priceString && <OptionPrice>{priceString}</OptionPrice>}
    </OptionContainer>
  )
}

Option.propTypes = {
  id: PropTypes.number,
  isDefault: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.array,
  group: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.string,
}

export default Option
