import React from 'react'
import ReactDOM from 'react-dom'

import { MobileScreen } from 'utils/screen'
import { VenueStatusPortal } from './styled'

import isNode from 'detect-node'

export default class extends React.PureComponent {
  render() {
    if (isNode) return null

    return (
      <MobileScreen>
        <React.Fragment>
          {ReactDOM.createPortal(
            <VenueStatusPortal id="venue-status-portal" />,
            document.body,
          )}
        </React.Fragment>
      </MobileScreen>
    )
  }
}
