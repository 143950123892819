import styled from 'styled-components'

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
`

export const RadioCircle = styled.span`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: ${props => props.size}px;
  width: ${props => props.size}px;

  border-radius: 50%;
  border: 1px solid
    ${props =>
      props.theme.colors[props.isChecked ? props.color : props.baseColor]};
`

export const RadioMark = styled.span`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors[props.color]};
`

export const RadioContainer = styled.label`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  margin-right: 12px;
  :hover {
    ${RadioCircle} {
      border-color: ${props => props.theme.colors[props.color]};
    }
  }
`
