import React, { Component } from 'react'

import isEmpty from 'lodash/isEmpty'

import { OptionGroupsContainer } from './styled'
import OptionGroup from './OptionGroup'

class OptionGroups extends Component {
  render() {
    const {
      optionGroups,
      selectedOptions,
      handleOptionsChange,
      index,
    } = this.props
    if (isEmpty(optionGroups)) {
      return null
    }
    return (
      <OptionGroupsContainer>
        {optionGroups.map(group => {
          const { min, max, type } = group
          const isRequired = type === 'RADIO' || Number(group.min) > 0
          const selectedOptionsLength = selectedOptions[index].length
          const hasUnmetRequirements =
            (isRequired && selectedOptionsLength === 0) ||
            (min && selectedOptionsLength < min) ||
            (max && selectedOptionsLength > max)
          return (
            <OptionGroup
              key={group.name}
              index={index}
              required={isRequired}
              hasUnmetRequirements={hasUnmetRequirements}
              value={selectedOptions[index] || []}
              {...group}
              onChange={handleOptionsChange}
            />
          )
        })}
      </OptionGroupsContainer>
    )
  }
}

export default OptionGroups
