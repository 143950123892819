import React from 'react'
import PT from 'prop-types'
import Transition from 'react-transition-group/Transition'

import {
  VenueStatusWrapper,
  VenueStatusContent,
  VenueStatusDescription,
  VenueStatusTitle,
  VenueStatusIcon,
} from './styled'

import VenueStatusPortal from './VenueStatusPortal'

import Icon from 'components.v2/Icon/IconV2'
import humaniseStatus from 'components.v2/VenueImage/humaniseStatus'

const duration = 100

const defaultStyle = {
  height: 0,
}

const transitionStyles = {
  entering: { height: 0 },
  entered: { height: 70 },
}

const Content = ({ openingHours, status = 'CLOSED' }) => {
  return (
    <VenueStatusContent>
      <VenueStatusIcon>
        <Icon src="Shopclosed.svg" />
      </VenueStatusIcon>
      <VenueStatusDescription>
        <VenueStatusTitle>
          Sorry, we will open soon
          <span>
            {status === 'CLOSED'
              ? humaniseStatus(status, openingHours)
              : `We're temporarily unavailable`}
          </span>
        </VenueStatusTitle>
      </VenueStatusDescription>
    </VenueStatusContent>
  )
}

// This makes sure that everything is already mounted
// before showing the VenueStatus notification so it wont
// look janky or eratic.
const DELAY = 2500

export default class extends React.Component {
  state = {
    isOpen: false,
  }

  static propTypes = {
    suburb: PT.string,
    city: PT.string,
    isOpen: PT.bool,
    openingHours: PT.array,
  }

  static defaultProps = {
    suburb: '',
    city: '',
    isOpen: false,
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({
        isOpen: true,
      })
    }, DELAY)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    return (
      <VenueStatusPortal>
        <Transition in={this.state.isOpen} timeout={duration}>
          {state => (
            <VenueStatusWrapper
              style={{ ...defaultStyle, ...transitionStyles[state] }}
            >
              <Content {...this.props} />
            </VenueStatusWrapper>
          )}
        </Transition>
      </VenueStatusPortal>
    )
  }
}
