import React, { Component } from 'react'
import PropTypes from 'prop-types'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import Decimal from 'decimal.js'

import { getPrice } from 'utils/currency'
import ModalItemImage from 'components.v2/ModalItemImage'
import NoImage from 'components.v2/NoImage'

import { formatMenu } from 'pages/vendor/modules/menu'
import NumberPicker from './NumberPicker'
import OptionGroups from './OptionGroups'
import noScroll from 'no-scroll'
import Modal from 'components.v2/MenuModal'
import { formatPrice } from 'utils/currency'
import ReactContentLoader from 'react-content-loader'

import {
  ModalContainer,
  ItemInfo,
  HeaderText,
  HeaderDescription,
  HeaderContainer,
  ItemModifier,
  ButtonContainer,
  AddToCart,
  ItemInfoContainer,
  HeaderPlaceHolder,
  QuantityContainer,
  ImagePlaceHolder,
} from './styled'

import { getDefaultOptions, getSelectedOptions } from './helpers'

class MenuModal extends Component {
  state = {
    expand: false,
    top: 0,
    initial: 50,
    isLoadingImage: false,
    hasError: false,
    imgSrc: null,
  }

  static getDerivedStateFromProps(props, state) {
    // Reset selected options if menu changes
    const { menu } = props

    if (menu.id !== state.menuId) {
      const quantity = get(menu, 'quantity', 1)

      const selectedOptionIds = JSON.parse(get(menu, 'serialisedOptions', '[]'))

      return {
        quantity,
        selectedOptionIds: !isEmpty(selectedOptionIds)
          ? selectedOptionIds
          : getDefaultOptions(formatMenu(menu)),
        menuId: menu.id,
      }
    }

    return null
  }

  get expand() {
    return this.state.expand
  }

  get quantity() {
    return this.state.quantity
  }

  get selectedOptions() {
    return getSelectedOptions(
      flatten(this.state.selectedOptionIds),
      formatMenu(this.props.menu),
    )
  }

  handleOptionsChange = (index, options) => {
    let selectedOptionIds = [...this.state.selectedOptionIds]
    selectedOptionIds[index] = options

    this.setState({
      selectedOptionIds,
    })
  }

  handleQuantityChange = quantity => {
    this.setState({ quantity })
  }

  addOrder = unitPrice => {
    const { menu } = this.props
    const { quantity, selectedOptionIds } = this.state

    this.props.action({ ...menu, quantity, unitPrice, selectedOptionIds })
    this.props.close()
  }

  checkForIssues() {
    const optionGroups = formatMenu(this.props.menu)
    const { selectedOptionIds } = this.state
    return optionGroups.some((group, index) => {
      const { type, min, max } = group
      const selectedOptionLength = selectedOptionIds[index].length
      if (
        (type === 'RADIO' && selectedOptionLength !== 1) ||
        (parseInt(min, 10) && selectedOptionLength < parseInt(min, 10)) ||
        (parseInt(max, 10) && selectedOptionLength > parseInt(max, 10))
      ) {
        return true
      }

      return false
    })
  }

  toggleExpand = () => {
    this.setState({
      ...this.state,
      expand: !this.expand,
    })
  }

  dragMouseDown = e => {
    e = e || window.event
    e.preventDefault()
    this.setState({
      ...this.state,
      initial: e.clientY || e.changedTouches[0].clientY,
      top: e.clientY || e.changedTouches[0].clientY,
    })
    document.onmouseup = this.closeDragElement
    document.onmousemove = this.elementDrag
    document.ontouchmove = this.elementDrag
    document.ontouchend = this.closeDragElement
  }

  elementDrag = e => {
    e = e || window.event
    e.preventDefault()

    let offset =
      (e.clientY || e.changedTouches[0].clientY) -
      (this.state.initial || e.clientY || e.changedTouches[0].clientY)

    this.setState({
      ...this.state,
      offset,
      top: e.clientY,
      expand: this.expand
        ? offset < -50
          ? false
          : this.expand
        : offset > 50
        ? true
        : this.expand,
    })
  }

  closeDragElement = () => {
    document.onmouseup = null
    document.onmousemove = null
    document.ontouchend = null
    document.ontouchmove = null
  }

  render() {
    const { isOpen, close, menu, isUpdateItem } = this.props

    if (!menu) {
      return null
    }

    if (isOpen) {
      noScroll.on()
    } else {
      noScroll.off()
    }

    const { quantity, selectedOptionIds } = this.state
    const { name, description, price } = menu
    const optionGroups = formatMenu(this.props.menu)
    const optionsPrice = this.selectedOptions.reduce(
      (acc, curr) => acc + getPrice(curr.price),
      0,
    )

    const unitPrice = new Decimal(getPrice(price)).plus(optionsPrice).toNumber()
    const totalPrice = new Decimal(quantity || 1).mul(unitPrice).toNumber()
    const action = {
      action: () => this.addOrder(unitPrice),
      label: `${isUpdateItem ? 'Update item' : 'Add to cart'} - ${formatPrice(
        totalPrice,
      )}`,
      color: 'orange',
      disabled: this.checkForIssues(),
    }

    let handleAction = () => {
      action.action()
      close()
      noScroll.off()
    }

    const setImageStatus = imageStatus => {
      this.setState({
        ...this.state,
        ...imageStatus,
      })
    }

    return (
      <Modal
        isOpen={isOpen}
        close={close}
        action={action}
        onRequestClose={close}
        noCloseButton
      >
        <ModalContainer>
          <ModalItemImage
            imageStatus={this.state}
            setImageStatus={setImageStatus}
            {...this.props}
          />
          {this.state.isLoadingImage && !this.state.hasError && (
            <ImagePlaceHolder>
              <ReactContentLoader
                height="600"
                width="600"
                style={{ borderRadius: '12px 0px 0px 12px' }}
              />
            </ImagePlaceHolder>
          )}

          <ItemInfoContainer className={this.state.hasError ? 'no-image' : ''}>
            <ItemInfo
              onTouchStart={this.dragMouseDown}
              onMouseDown={this.dragMouseDown}
              className={this.state.hasError ? 'no-image' : ''}
            >
              <NoImage
                handleClick={this.toggleExpand}
                expand={this.expand}
                {...this.props}
              >
                <HeaderPlaceHolder />
              </NoImage>

              <HeaderContainer>
                <HeaderText>{name}</HeaderText>
                <HeaderDescription>{description}</HeaderDescription>
              </HeaderContainer>

              {optionGroups.length > 0 &&
                optionGroups.map((optionGroup, index) => (
                  <ItemModifier key={index}>
                    <OptionGroups
                      optionGroups={[optionGroup]}
                      index={index}
                      selectedOptions={selectedOptionIds}
                      handleOptionsChange={this.handleOptionsChange}
                    />
                  </ItemModifier>
                ))}

              <QuantityContainer>
                <NumberPicker
                  value={this.state.quantity}
                  onChange={this.handleQuantityChange}
                />
              </QuantityContainer>
            </ItemInfo>
            <ButtonContainer>
              <AddToCart disabled={action.disabled} onClick={handleAction}>
                {action.label}
              </AddToCart>
            </ButtonContainer>
          </ItemInfoContainer>
        </ModalContainer>
      </Modal>
    )
  }
}

MenuModal.propTypes = {
  action: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  name: PropTypes.string,
  menu: PropTypes.object,
}

export default MenuModal
