import React from 'react'
import PT from 'prop-types'
import Button from 'components.v2/Button'
import { LoadMore } from './styled'

const Component = ({ onClick, isLoading }) => {
  return (
    <LoadMore>
      <Button
        className="w-full"
        disabled={isLoading}
        loading={isLoading}
        onClick={onClick}
        variant="primary"
      >
        Load more
      </Button>
    </LoadMore>
  )
}

Component.propTypes = {
  isLoading: PT.bool,
  onClick: PT.func,
}

export default Component
