import styled from 'styled-components'
import media from 'utils/media'

import { factory as locationSearchBarFactory } from 'common/LocationSearchBar'

export const ReadyToOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
`

export const Heading = styled.h3`
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 500;
  color: #333333;

  ${media.screen`
    font-size: 24px;
    margin-bottom: 20px;
  `}
`

export const Copy = styled.p`
  max-width: 300px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #808080;
  font-weight: 300;
  text-align: center;

  ${media.screen`
    display: none;
  `}
`

export const LocationSearchBar = locationSearchBarFactory({
  Container: x => styled(x)`
    border-top-color: ${props => (props.hasFocus ? '#f47325' : 'transparent')};
    border-left-color: ${props => (props.hasFocus ? '#f47325' : 'transparent')};
    border-bottom-color: ${props =>
      props.hasFocus ? '#f47325' : 'transparent'};
  `,
})
