import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import isNode from 'detect-node'
import ResizeSensor from 'css-element-queries/src/ResizeSensor'

export default class ShadowScrollBox extends Component {
  state = { pos: null }
  containerRef = React.createRef()
  running = false

  updatePos = () => {
    let pos
    const node = this.containerRef && this.containerRef.current

    if (!node) return

    const { scrollHeight, offsetHeight, scrollTop } = node

    if (scrollHeight === offsetHeight) pos = null
    else if (scrollTop <= 0) pos = 'top'
    else if (offsetHeight + scrollTop >= scrollHeight) pos = 'bot'
    else pos = 'mid'

    this.state.pos !== pos && this.setState({ pos })
  }

  listener = () => {
    const node = this.containerRef && this.containerRef.current

    if (!node || this.running) return

    this.running = true

    window.requestAnimationFrame(() => {
      this.updatePos()
      this.running = false
    })
  }

  componentDidMount = () => {
    if (isNode) return
    this.updatePos()
    const node = this.containerRef.current
    node.addEventListener('scroll', this.listener)
    new ResizeSensor(node, this.updatePos)
  }

  componentWillUnmount = () => {
    if (isNode) return
    this.containerRef.current.removeEventListener('scroll', this.listener)
  }

  render = () => (
    <Fragment>
      <Container ref={this.containerRef} {...this.props}>
        {this.props.children}
      </Container>
    </Fragment>
  )
}

const Container = styled.div`
  overflow-y: scroll;
  max-height: calc(100vh - 320px);
`
