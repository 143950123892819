import React, { Fragment } from 'react'
import PT from 'prop-types'

/**
 * @component
 * Transform text with line breaks to React elements with line breaks. Passes all other props to
 * `<p>` (if `<p>` is used to break lines)
 */
const Paragraphs = ({ children, method = 'p', ...props }) =>
  !children ? null : (
    <Fragment>
      {children.split('\n').map((para, i, paras) => {
        const isPara = method === 'p'
        const isLast = i !== paras.length - 1

        return (
          <Fragment key={i}>
            {isPara ? <p {...props}>{para}</p> : para}
            {!isPara && isLast && (
              <Fragment>
                {method === 'br' && <br />}
                {method === 'brbr' && (
                  <Fragment>
                    <br />
                    <br />
                  </Fragment>
                )}
              </Fragment>
            )}
          </Fragment>
        )
      })}
    </Fragment>
  )

Paragraphs.propTypes = {
  /** Method to break lines. `p` for `<p>`, `br` for `<br>`, `brbr` for `<br><br>` */
  method: PT.oneOf(['p', 'br', 'brbr']),
  /** text to be transformed */
  children: PT.string,
}

export default Paragraphs
