export default () => {
  let modalRoot = document.createElement('div')
  modalRoot.id = 'modal-root'

  modalRoot.style.position = 'fixed'
  modalRoot.style.width = '100%'
  modalRoot.style.bottom = '0'

  document.body.appendChild(modalRoot)

  return modalRoot
}
