import React from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import StyleableModal from './StylableModal'
import media from 'utils/media'

const Modal = ({
  children,
  noCloseButton,
  small,
  onRequestClose,
  ...props
}) => (
  <ModalCmpt onRequestClose={onRequestClose} {...props}>
    <CloseButton onClick={onRequestClose}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.29031 4.29031C3.90323 4.6774 3.90323 5.30499 4.29031 5.69207L10.5982 12L4.29032 18.3079C3.90323 18.695 3.90323 19.3226 4.29032 19.7097C4.6774 20.0968 5.30499 20.0968 5.69208 19.7097L12 13.4018L18.3079 19.7097C18.695 20.0968 19.3226 20.0968 19.7097 19.7097C20.0968 19.3226 20.0968 18.695 19.7097 18.3079L13.4018 12L19.7097 5.69208C20.0968 5.30499 20.0968 4.6774 19.7097 4.29031C19.3226 3.90323 18.695 3.90323 18.3079 4.29031L12 10.5982L5.69208 4.29031C5.30499 3.90323 4.6774 3.90323 4.29031 4.29031Z"
          fill="black"
        />
      </svg>
    </CloseButton>
    {children}
  </ModalCmpt>
)

Modal.propTypes = {
  children: PT.node.isRequired,
  noCloseButton: PT.bool,
  small: PT.bool,
  onRequestClose: PT.func,
}

export default Modal

const ModalCmpt = styled(StyleableModal)`
  

&__content {
    position: absolute;
    display: block;
    max-width: 1100px;
    background-color: ${p => p.theme.colors.white};
    outline: none !important;
    border-radius: 12px;

    ${media.phone`
      position: static;
      width: 100vw;
      max-width: 100vw;
    `}
    ${media.tablet`
      position: static;
      width: 100vw;
      max-width: 100vw;
    `}
    ${media.screen`
      position: static;
      width: 100vw;
      max-width: 100vw;
    `}
  }

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    z-index: 1005;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`
const CloseButton = styled.button`
  position: absolute;
  left: 20px;
  top: 20px;
  border: 0;
  padding: 0;
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.18);
  z-index: 1;

  width: 48px;
  height: 48px;

  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
`
