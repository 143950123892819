import styled from 'styled-components'
import { Text } from 'components/Text'

export const NumberPickerContainer = styled.div`
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
`

export const ActionButton = styled.button`
  padding: 0 5px;
  cursor: pointer;
  border: 0;
  outline: none;
  background-color: transparent;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  font-size: 18px;
  line-height: 100%;
  font-weight: 300;
  color: ${props => props.theme.colors[props.color] || props.color};
`

export const NumberDisplay = styled(Text)`
  padding: 0 5px;
`
