import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import styled from 'styled-components'

import imageURL from 'utils/imageURL'

const DIMENSIONS = {
  width: 200,
  height: 200,
}

export const BlankDiv = styled.div`
  margin-bottom: 40px;
`

const ImgCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Img = styled.img`
  width: ${p => (p.width ? p.width : DIMENSIONS.width)}px;
  height: ${p => (p.height ? p.height : DIMENSIONS.height)}px;
  border-radius: ${p => (p.isCirle ? '50%' : '12px')};
`

const VenueGenericImage = memo(({ image, name, dimensions, isCirle }) => {
  const [hasError, setHasError] = useState(false)
  const [imageSrc, setImageSrc] = useState(null)

  const DEFAULT_IMAGE = 'beattheq-200x200.jpg'

  useEffect(() => {
    loadImage(image)
  }, [image])

  const loadImage = img => {
    const imageUrl = getImageUrl(img)
    const newImg = new Image()
    newImg.src = imageUrl
    newImg.onload = () => {
      setImageSrc(imageUrl)
    }
    newImg.onerror = () => {
      setHasError(true)
    }
  }

  const getImageSrc = () => {
    return hasError ? '' : imageSrc
  }

  const getImageUrl = logoImage => {
    return imageURL(logoImage, get(dimensions, 'image'))
  }

  if (
    isEmpty(image) ||
    isEmpty(getImageSrc()) ||
    image.substring(image.lastIndexOf('/') + 1) === DEFAULT_IMAGE
  ) {
    return <BlankDiv />
  }

  return (
    <ImgCont>
      <Img
        src={getImageSrc()}
        alt={name}
        height={get(dimensions, 'style.height')}
        width={get(dimensions, 'style.width')}
        isCirle={isCirle}
      />
    </ImgCont>
  )
})

VenueGenericImage.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dimensions: PropTypes.shape({
    image: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
    style: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  isCirle: PropTypes.bool,
}

VenueGenericImage.defaultProps = {
  dimensions: {
    image: DIMENSIONS,
    style: DIMENSIONS,
  },
}

export default VenueGenericImage
