import styled from 'styled-components'
import { mobileFirstMedia as media } from 'utils/media'

/**
 * @component
 * A `styled.div` element. Please see [Card](#Card)'s for example.
 */
export default styled.div`
  padding: 30px 15px 30px;
  font-size: 14px;
  line-height: 150%;
  color: ${p => p.theme.colors.textSecondary};

  ${media.screen`
    padding: 30px;
  `}
`
