import styled from 'styled-components'

export const OptionGroupsContainer = styled.div`
  margin-top: 10px;

  width: 100%;
`
export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;

  gap: 32px;
`

export const OptionHeader = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  color: #000000;
`
export const OptionSubHeader = styled.div`
  letter-spacing: 0.4px;
  font-size: 14px;
  font-weight: 300;
  color: ${props => (props.error ? '#f26722' : 'inherit')};
`

export const OptionForm = styled.form`
  :not(:first-child) {
    margin-top: 30px;
  }

  & > div:last-of-type {
    padding-bottom: 32px;
  }
`
