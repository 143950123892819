import styled from 'styled-components'
import { mobileFirstMedia as media } from 'utils/media'

import Text from 'components.v2/Text'

export const Form = styled.form`
  width: 100%;

  ${media.screen`
    width: 345px;
  `}
`

export const FormMsg = styled(Text)`
  float: right;
  line-height: 20px;
  margin-top: 5px;
  display: inline-block;
  letter-spacing: normal;
`
