import React from 'react'
import styled from 'styled-components'
import colors from 'theme/colors'
import { mobileFirstMedia as media } from 'utils/media'

export const HistoryViewWrapper = styled.div`
  margin-bottom: 15px;
  position: relative;
`

export const ListHeadWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`

export const ListHead = styled.div`
  border-top: solid 1px ${colors.gray5};
  border-bottom: solid 1px ${colors.gray5};
`

export const ListHeadInner = styled.div`
  color: ${colors['textDark']};
  display: flex;

  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 0;
  text-transform: uppercase;

  height: 33px;
  padding-left: 15px;
  padding-right: 15px;
`

export const ListHeadLeft = styled.div`
  line-height: 33px;
  width: 40%;
`

export const ListHeadRight = styled.div`
  flex: 1;
  line-height: 33px;
  margin-left: auto;
  text-align: right;
  padding-right: 30px;
`

export const ListItems = styled.div`
  display: flex;
  flex-direction: column;
`

export const ListItem = styled.div`
  min-height: 70px;
`

export const ListItemBody = styled.div`
  height: 70px;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;

  p {
    letter-spacing: 0.5px;
  }

  &:hover {
    background-color: ${colors['lightestTeal']};
  }
`

export const ListItemBodyInner = styled.div`
  border-bottom: solid 1px ${colors.gray5};
  cursor: pointer;
  display: flex;
  height: 70px;
  width: 100%;
  align-items: center;
  padding-left: 15px;
`

export const ListItemDescription = styled.div`
  flex: 1;
`

export const ListItemStatus = styled.div`
  padding-right: 15px;
  text-align: right;
  display: flex;
  align-items: center;
`

export const ListItemStatusDesc = styled.div`
  flex: 1;
  padding-right: 20px;

  p {
    text-align: right !important;
  }
`

export const ListChevron = styled.div`
  float: right;
  margin-left: auto;
  color: ${colors.gray3};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
`

export const LoadMore = styled.div`
  text-align: center;
  padding-top: 30px;
  width: 150px;
  margin: auto;
`

export const ListItemSummary = styled.div`
  display: block;

  ${media.screen`
    display: flex;
  `}
`

export const ListItemSummaryLeft = styled.div`
  background-color: ${colors['lightestTeal']};
  border-right: none;
  display: block;

  ${media.screen`
    flex: 1;
    border-right: solid 1px ${colors.gray5};
  `}
`

export const ListItemSummaryRight = styled.div`
  width: 100%;
  ${media.screen`
    width: 270px;
  `}
`

export const ButtonWrapper = styled.div`
  padding: 15px;
`

export const DividerWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  > div {
    border-bottom: solid 1px ${colors.gray5};
  }
`

export const ListItemDivider = () => {
  return (
    <DividerWrapper>
      <div />
    </DividerWrapper>
  )
}

export const OrderItems = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
`

export const OrderItemWrapper = styled.div`
  display: flex;
  min-height: 70px;
  width: 100%;
  align-items: center;
  border-bottom: solid 1px ${colors.gray5};
  &:last-child {
    border-bottom: none;
  }
`

export const OrderItemInner = styled.div`
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  width: 100%;
`

export const OrderQuantity = styled.div`
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  margin-right: 15px;
  border: solid 1px ${colors.gray5};
  background-color: white;
  font-size: 12px;
  font-weight: 400;
`

export const OrderDesc = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  p {
    letter-spacing: 0.5px;
    margin-bottom: 0;
  }
`

export const OrderAmount = styled.div`
  margin-left: auto;
`

export const OrderItem = ({ children }) => {
  return (
    <OrderItemWrapper>
      <OrderItemInner>{children}</OrderItemInner>
    </OrderItemWrapper>
  )
}

export const DiscountWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
  width: 100%;
  background-color: #eaf4f4;
  padding-left: 30px;
  padding-right: 30px;
  color: ${colors.teal};

  p {
    letter-spacing: 0.5px;
  }

  p:first-child {
    flex: 1;
  }
`

export const TotalWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
`

export const TotalLabel = styled.div`
  flex: 1;
`

export const TotalValue = styled.div`
  margin-left: auto;
`

export const TotalRow = ({ children = [] }) => {
  return (
    <TotalWrapper>
      <TotalLabel>{children[0]}</TotalLabel>
      <TotalValue>{children[1]}</TotalValue>
    </TotalWrapper>
  )
}

export const PaymentSourceWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  height: 70px;
  align-items: center;
`

export const PaymentSource = ({ children = [] }) => {
  return (
    <PaymentSourceWrapper>
      <TotalLabel>{children[0]}</TotalLabel>
      <TotalValue>{children[1]}</TotalValue>
    </PaymentSourceWrapper>
  )
}

export const NoteWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 5px;
  padding: 15px 30px;
`

export const NoteLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
`

export const Note = styled.div`
  display: flex;
  justify-content: flex-start;
  background: #fdf0e9;
  border: 1px dashed #f8b390;
  border-radius: 4px;
  width: 100%;
  padding: 12px;
  font-size: 13px;
  font-weight: 500;
`

export const OrderNote = ({ children = [] }) => {
  return (
    <NoteWrapper>
      <NoteLabel>{children[0]}</NoteLabel>
      <Note>{children[1]}</Note>
    </NoteWrapper>
  )
}

export const SourceLeft = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 60%;
`

export const CardWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const Card = styled.img`
  margin-right: 15px;
  width: 40px;
  height: 30px;
`

export const CardLabel = styled.div`
  font-size: 13px;
  letter-spacing: 0.5px;
  color: ${colors['textDark']};
`
