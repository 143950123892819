import React, { Component } from 'react'
import PT from 'prop-types'

import {
  AvatarWrapper,
  AvatarImg,
  AvatarTitle,
  AvatarEdit,
  AvatarEmpty,
  AvatarDate,
} from './styled'

class AccountMenuAvatar extends Component {
  static defaultProps = {
    src: '',
    firstname: '',
    lastname: '',
    editable: true,
    dateText: null,
  }

  static propTypes = {
    src: PT.string,
    firstname: PT.string,
    lastname: PT.string,
    editable: PT.bool,
    dateText: PT.string,
  }

  getInitial(name) {
    if (!name) return ''
    return name.split('')[0].toUpperCase()
  }

  render() {
    const { src, firstname, lastname, editable, dateText } = this.props
    return (
      <AvatarWrapper>
        {!src && (
          <AvatarEmpty>
            {this.getInitial(firstname)}
            {this.getInitial(lastname)}
          </AvatarEmpty>
        )}
        {src && <AvatarImg className="menu__avatar" src={src} />}
        <AvatarTitle>
          {firstname} {lastname}
        </AvatarTitle>
        {editable && (
          <AvatarEdit to="/account/profile">Edit Profile</AvatarEdit>
        )}
        {dateText && (
          <AvatarDate className="last_update">{dateText}</AvatarDate>
        )}
      </AvatarWrapper>
    )
  }
}

export default AccountMenuAvatar
