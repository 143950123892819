import React from 'react'
import PT from 'prop-types'

import {
  AccountCard,
  AccountCardTitle,
  AccountCardHeader,
} from './../../components/AccountCard'

import { BalanceAmount } from './styled'

const Balance = ({ balance = {} }) => {
  return (
    <AccountCard>
      <AccountCardHeader>
        <AccountCardTitle hasLead>Your balance:</AccountCardTitle>
        <BalanceAmount>{balance.available || '$0'}</BalanceAmount>
      </AccountCardHeader>
    </AccountCard>
  )
}

Balance.propTypes = {
  total: PT.number,
}

export default Balance
