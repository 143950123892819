import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import isNode from 'detect-node'
import createModalRoot from 'utils/createModalRoot'
import { EmptyDiv } from './styled'

export default class extends React.PureComponent {
  constructor(props) {
    super(props)

    this.el = document.createElement('div')
    this.modalRoot = document.getElementById('modal-root')
  }

  componentDidMount() {
    if (isNode) return
    if (!this.modalRoot) {
      this.modalRoot = createModalRoot()
    }
    this.modalRoot.insertBefore(this.el, this.modalRoot.firstChild)
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el)
  }

  render() {
    if (isNode) return null

    return (
      <Fragment>
        {ReactDOM.createPortal(this.props.children, this.el)}
        {ReactDOM.createPortal(<EmptyDiv />, document.body)}
      </Fragment>
    )
  }
}
