import React from 'react'
import { oneOfType, func, object, arrayOf, string, bool } from 'prop-types'
import { Link as ScrollLink } from 'react-scroll'
import isMatch from 'lodash/fp/isMatch'

import animateFactory from 'utils/animate'
import slugify from 'utils/slugify'

const SCROLL_ANIMATION_DURATION = 500

export default class ScrollSpyNav extends React.Component {
  static propTypes = {
    renderContainer: oneOfType([func, object, string]).isRequired,
    renderItem: oneOfType([func, object, string]).isRequired,
    list: arrayOf(string),
    scrollLinkProps: object,
    alwaysShowActive: bool,
  }

  static defaultProps = {
    renderContainer: 'div',
    renderItem: 'div',
    list: [],
    scrollLinkProps: {},
  }

  state = {
    activeIndex: 0,
    isIncreasing: true,
  }

  containerRef = React.createRef()
  itemRefs = {}
  animate = animateFactory(SCROLL_ANIMATION_DURATION)

  showActive = id => {
    const container = this.containerRef.current
    const item = this.itemRefs[id]

    if (!id || !container || !item) return

    const itemLeftPos = item.offsetLeft
    const itemWd = item.offsetWidth
    const containerWd = container.offsetWidth

    const init = container.scrollLeft
    const tgt = Math.max(0, itemLeftPos - containerWd / 2 + itemWd / 2)

    this.animate(progress => {
      container.scrollLeft = init + (tgt - init) * progress
    })
  }

  handleEnter = item => {
    const currState = this.state
    const { activeIndex } = currState
    const { list } = this.props

    const nextIndex = list.map(slugify).indexOf(item)
    const nextState = {
      activeIndex: nextIndex,
      isIncreasing: nextIndex > activeIndex,
    }

    if (!isMatch(nextState, currState)) {
      this.setState(nextState)

      if (this.props.alwaysShowActive) this.showActive(slugify(item))
    }
  }

  handleLeave = item => {
    const { list } = this.props
    const { isIncreasing } = this.state

    if (this.state.activeIndex === list.map(slugify).indexOf(item)) {
      const activeIndex = isIncreasing ? list.length - 1 : 0

      this.setState({ activeIndex })

      if (this.props.alwaysShowActive)
        this.showActive(slugify(list[activeIndex]))
    }
  }

  render = () => {
    const {
      renderContainer: Container,
      renderItem: Item,
      list,
      scrollLinkProps,
    } = this.props

    const { activeIndex } = this.state

    return (
      <Container ref={this.containerRef}>
        {list.map((item, i) => (
          <Item
            key={`${slugify(item)}-${i}`}
            className={i === activeIndex ? 'active' : ''}
            ref={ref => {
              this.itemRefs[slugify(item)] = ref
            }}
          >
            <ScrollLink
              to={slugify(item)}
              smooth
              spy
              onSetActive={this.handleEnter}
              onSetInactive={this.handleLeave}
              style={{ cursor: 'pointer' }}
              {...scrollLinkProps}
            >
              {item}
            </ScrollLink>
          </Item>
        ))}
      </Container>
    )
  }
}
