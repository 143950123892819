export const getDefaultOptions = (optionGroups = []) =>
  optionGroups.map(group =>
    group.options
      .filter(option => option.isDefault)
      .map(option => option.id))
    .filter(id => id)

const getOptions = (options = []) => options.reduce((acc, curr) => [...acc, ...curr.options], [])

export const getSelectedOptions = (ids, optionsGroup) => {
  const options = getOptions(optionsGroup)
  return options.filter(option => ids.indexOf(option.id) > -1)
}
