import React from 'react'
import PT from 'prop-types'
import scroller from 'services/scroller'
import Icon from 'components/Icon.v2'
import styled from 'styled-components'
import { lifecycle, withState, compose } from 'recompose'

/** Scrolls to top when clicked. Visible when scrolled down only. Passes props to the parent `<styled.div>` element. */
const ScrollToTopButton = ({ isVisible, ...props }) =>
  isVisible ? (
    <FixedButtonContainer {...props}>
      <Button onClick={() => scroller.animateScroll(0)}>
        <Icon type="arrowUp" size={20} fill="textAltPrimary" />
      </Button>
    </FixedButtonContainer>
  ) : null

ScrollToTopButton.propTypes = {
  /** @ignore */ isVisible: PT.bool.isRequired,
}

const withContainer = compose(
  withState('isVisible', 'setVisibility', false),
  lifecycle({
    setVisibility() {
      if (!isTop() !== this.props.isVisible) {
        this.props.setVisibility(!isTop())
      }
    },

    componentDidMount() {
      this.setVisibility()
      window.addEventListener('optimizedScroll', () => this.setVisibility())
    },

    componentWillUnmount() {
      window.addEventListener('optimizedScroll', () => this.setVisibility())
    },
  }),
)

export default withContainer(ScrollToTopButton)

const isTop = () => {
  const body = window.document.body // IE 'quirks'
  const document = window.document.documentElement // IE with doctype
  const availableReference = document.clientHeight ? document : body

  return availableReference.scrollTop === 0
}
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.colors.secondary};
  width: 60px;
  height: 60px;
`

const FixedButtonContainer = styled.div`
  position: fixed;
  right: 15px;
  bottom: 15px;
`
