import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet-async'
import isNode from 'detect-node'

import { withSetLayout } from './../GlobalLayout/Root'

export const createPageLayout = settings => {
  const PageLayout = ({ setLayout, title, children, header, footer }) => {
    // somehow breaks the SSR.
    // still under investigation
    if (!isNode) {
      useEffect(() => {
        setLayout({
          ...settings,
          ...(header === false && { header }),
          ...(footer === false && { footer }),
        })
      }, [])
    }

    return (
      <Fragment>
        {title && <Helmet title={`${title} | Order ahead with Hey You`} />}
        {children}
      </Fragment>
    )
  }

  PageLayout.propTypes = {
    title: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  }

  return withSetLayout(PageLayout)
}

export default createPageLayout({ header: true, footer: true })
