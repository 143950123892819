import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { number, string, array, shape } from 'prop-types'
import get from 'lodash/get'
import humaniseStatus from 'components.v2/VenueImage/humaniseStatus'

import { createDescription } from 'pages/search/venues'
import VenueImage from 'components.v2/VenueImage'
import Text from 'components.v2/Text'
import slugify from 'utils/slugify'

const VenueCard = props => {
  const {
    id,
    name,
    tags,
    className,
    location = {},
    discount = {},
    onClick = () => {},
    locationRouter,
    status,
    openingHours,
  } = props

  // NOTE: tags fallback logic must be placed in the body rather than in the
  // arguments, as it will not run when tags is set to null
  const description = createDescription(location.distance, tags || [])

  return (
    <section className={className} onClick={onClick}>
      <VenueImage {...props} />

      {discount.title && (
        <DiscountTitle className="inline-block mt-15 px-10">
          {discount.title}
        </DiscountTitle>
      )}

      <Text
        className={`block ${discount.title ? 'mt-5' : 'mt-12pt5'}`}
        as={Link}
        to={`/restaurant/${id}/${slugify(name)}`}
        bold="true"
      >
        {name}
      </Text>

      {description && (
        <Text className="block" variant="tiny" color="gray3">
          {description}
        </Text>
      )}

      {discount.time && (
        <Text color="secondary" variant="tertiary">
          {discount.time}
        </Text>
      )}

      {get(locationRouter, 'pathname', '').indexOf('/search') === 0 && (
        <Text color="secondary" variant="tiny">
          {humaniseStatus(status, openingHours)}
        </Text>
      )}
    </section>
  )
}

VenueCard.propTypes = {
  id: number,
  name: string,
  tags: array,
  className: string,
  discount: shape({
    title: string.isRequired,
    time: string.isRequired,
  }),
}

const DiscountTitle = styled(Text).attrs({
  variant: 'tertiary',
  color: 'white',
})`
  lineheight: 20px;
  background-color: ${p => p.theme.colors.secondary};
`

export default VenueCard
