import React from 'react'
import PT from 'prop-types'

import SearchBarLayout from 'layouts/SearchBarLayout'
import DesktopContainer from 'layouts/DesktopContainer'
import Breadcrumbs from 'components.v2/Breadcrumbs'
import { Card, CardHeading, CardDivider, CardBody } from 'components.v2/Card'
import Button from 'components.v2/Button'
import Excerpt from 'components.v2/Excerpt'
import Paragraphs from 'components.v2/Paragraphs'
import ScrollToTopButton from 'components.v2/ScrollToTopButton'
import ReadyToOrder from 'common/ReadyToOrder'
import VenuesGrid from 'components.v2/VenuesGrid'
import NewsCorp from 'components.v2/NewsCorp'

const CategoryLayout = ({
  venues,
  onLoadMore,
  breadcrumbsLinks,
  mainHeading,
  secondaryHeading,
  description,
  readyToOrderHeading,
  readyToOrderCopy,
  isLoading,
  noMorePost,
  pageTitle,
}) => (
  <SearchBarLayout title={pageTitle}>
    <DesktopContainer background="bgSecondary" className="pb-20 xl:pb-100">
      <Breadcrumbs
        className="relative xl:static xl:mx-auto xl:mb-40 px-15 pt-30 pb-30 xl:pb-0 xl:px-0 xl:max-w-xl bg-bgPrimary xl:bg-transparent"
        links={breadcrumbsLinks}
      />

      <NewsCorp />

      {description && (
        <Card>
          <CardHeading className="pt-10 xl:pt-30">{mainHeading}</CardHeading>
          <CardDivider className="hidden xl:block" />
          <CardBody className="pt-15 xl:pt-30">
            <Excerpt className="xl:hidden" method="brbr">
              {description}
            </Excerpt>
            <div className="hidden xl:block">
              <Paragraphs method="brbr">{description}</Paragraphs>
            </div>
          </CardBody>
        </Card>
      )}

      <Card className="mt-20">
        <CardHeading level={2} left={secondaryHeading} />
        <CardDivider className="hidden xl:block" />
        <CardBody className="px-0 xl:px-15">
          <VenuesGrid isLoading={isLoading} venues={venues} />

          {!!venues.length && !noMorePost && (
            <Button
              variant="primary"
              className="block mt-30 xl:mt-60 mx-auto px-30"
              onClick={onLoadMore}
              loading={isLoading}
            >
              Load more
            </Button>
          )}
        </CardBody>
      </Card>
    </DesktopContainer>

    <ReadyToOrder heading={readyToOrderHeading} copy={readyToOrderCopy} />
    <ScrollToTopButton className="xl:hidden" />
  </SearchBarLayout>
)

CategoryLayout.propTypes = {
  breadcrumbsLinks: PT.arrayOf(PT.object).isRequired,
  mainHeading: PT.string.isRequired,
  description: PT.string,
  secondaryHeading: PT.string.isRequired,
  venues: PT.arrayOf(PT.object).isRequired,
  onLoadMore: PT.func.isRequired,
  readyToOrderHeading: PT.string,
  readyToOrderCopy: PT.string,
  isLoading: PT.bool.isRequired,
  noMorePost: PT.bool.isRequired,
  pageTitle: PT.string.isRequired,
}

export default CategoryLayout
