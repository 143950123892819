import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/Icon.v2'

import { NumberPickerContainer, Button, Value } from './styled'

class NumberPicker extends Component {
  add = () => {
    const { onChange, value } = this.props
    onChange(value + 1)
  }

  minus = () => {
    const { onChange, value } = this.props
    const newValue = value - 1
    newValue && onChange(newValue)
  }

  render() {
    const { value } = this.props
    return (
      <NumberPickerContainer>
        <Button
          disabled={value === 1}
          borderRadius={'8px 0px 0px 8px'}
          onClick={this.minus}
        >
          <Icon type="minus" size={16} />
        </Button>
        <Value>{value}</Value>
        <Button borderRadius={'0px 8px 8px 0px'} onClick={this.add}>
          <Icon type="plus" size={16} />
        </Button>
      </NumberPickerContainer>
    )
  }
}

NumberPicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
}

export default NumberPicker
