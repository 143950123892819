import React, { Fragment } from 'react'
import PT from 'prop-types'
import { Link } from 'react-router-dom'

import { DesktopScreen, MobileScreen } from 'utils/screen'
import events from 'const/analytics-events'
import Icon from 'components/Icon'
import withAnalytics from 'enhancers/withAnalytics'

import {
  CitiesList,
  CityLinkFactory,
  DesktopCityList,
  PlaceSelectorContainer,
} from './styled'

import cities from 'const/cities'

class PlaceSelector extends React.Component {
  state = {
    isOpen: false,
  }

  toggleOpen = ev => {
    if (ev) ev.preventDefault()

    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  handleSelect = city => {
    this.props.onSelectCity(city)
    this.toggleOpen()
  }

  CityLink = CityLinkFactory(
    this.props.createUrl === PlaceSelector.defaultProps.createUrl ? 'a' : Link,
  )

  render() {
    const { city, createUrl } = this.props
    const { isOpen } = this.state
    const icon = isOpen ? 'up' : 'down'
    const CityLink = this.CityLink
    const CityLinkDt = withAnalytics()(CityLink.Dt)
    const CityLinkMb = withAnalytics()(CityLink.Mb)
    return (
      <Fragment>
        <DesktopScreen>
          <DesktopCityList>
            {cities.map(c => (
              <CityLinkDt
                to={createUrl(c.name)}
                key={c.name}
                isactive={c.name === city.name ? 'true' : 'false'}
                onClick={() => this.handleSelect(c)}
                analytics={[
                  {
                    eventType: 'onClick',
                    options: {
                      ...events['HERO_CLICK_CITYTAB'],
                      label: c.name,
                    },
                  },
                ]}
              >
                {c.name}
              </CityLinkDt>
            ))}
          </DesktopCityList>
        </DesktopScreen>
        <MobileScreen>
          <PlaceSelectorContainer>
            <CityLinkMb
              to="#"
              isselected={'true'}
              onClick={this.toggleOpen}
              analytics={[
                {
                  eventType: 'onClick',
                  options: {
                    ...events['HERO_CLICK_CITYTAB'],
                    label: city.name,
                  },
                },
              ]}
            >
              {city.name}{' '}
              <Icon element="span" name={`fas fa-chevron-${icon}`} size={14} />
            </CityLinkMb>
            {isOpen && (
              <CitiesList>
                {cities
                  .filter(c => c.name !== city.name)
                  .map(c => (
                    <CityLinkMb
                      key={c.name}
                      to={createUrl(c.name)}
                      onClick={() => this.handleSelect(c)}
                      analytics={[
                        {
                          eventType: 'onClick',
                          options: {
                            ...events['HERO_CLICK_CITYTAB'],
                            label: c.name,
                          },
                        },
                      ]}
                    >
                      {c.name}
                    </CityLinkMb>
                  ))}
              </CitiesList>
            )}
          </PlaceSelectorContainer>
        </MobileScreen>
      </Fragment>
    )
  }
}

PlaceSelector.propTypes = {
  city: PT.shape({
    name: PT.string.isRequired,
  }).isRequired,
  onSelectCity: PT.func,
  createUrl: PT.func,
}

PlaceSelector.defaultProps = {
  city: cities[0],
  onSelectCity: () => {},
  createUrl: () => '#',
}

export default PlaceSelector
