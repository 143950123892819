import React, { Component } from 'react'
import qs from 'qs'

import { Form, FormMsg } from './styled'
import { getError } from 'utils/get-message'
import Icon from 'components.v2/Icon'
import Input, { InputButton } from 'components.v2/Input'
import PT from 'prop-types'
import serialize from 'form-serialize'
import Spinner from 'components.v2/Spinner'

export const SUCCESS_REDEEM_CODE_MESSAGE =
  'Woohoo! Your code has been successfully redeemed.'

export default class extends Component {
  static propTypes = {
    fetchProfile: PT.func.isRequired,
    redeemCode: PT.func.isRequired,
  }

  form = React.createRef()

  inputConfig = 'regular'

  state = {
    isSubmitting: false,
    hasError: false,
    hasSuccess: false,
  }

  toggle = () => {
    if (this.state.isSubmitting) return
    this.setState({
      hasError: false,
    })
  }

  handleSubmit = async event => {
    try {
      event.preventDefault()

      this.setState({ hasError: false, hasSuccess: false })

      const { voucherCode } = serialize(this.form.current, { hash: true })

      this.setState({ isSubmitting: true })

      if (!voucherCode) {
        throw new Error('Oh no! Your voucher code is invalid')
      }

      await this.props.redeemCode(voucherCode)

      await this.props.fetchProfile()

      this.setState({ isSubmitting: false, hasSuccess: true }, () => {
        this.props.notify({
          type: 'success',
          message: SUCCESS_REDEEM_CODE_MESSAGE,
        })
      })
    } catch (err) {
      this.setState(
        { hasError: true, isSubmitting: false, hasSuccess: false },
        () => {
          this.props.notify({
            type: 'error',
            message: getError(err),
          })
        },
      )
    }
  }

  getStateIcon() {
    if (this.state.isSubmitting) {
      return <Spinner variant="alt-primary" />
    } else {
      return <Icon type="arrowRight" size={20} fill="white" />
    }
  }

  get successMessage() {
    return (
      this.state.hasSuccess && (
        <FormMsg variant="tertiary" color="secondary">
          The code you have entered is invalid
        </FormMsg>
      )
    )
  }

  get errorMessage() {
    return (
      this.state.hasError && (
        <FormMsg variant="tertiary" color="primary">
          The code you have entered is invalid
        </FormMsg>
      )
    )
  }

  get initValue() {
    return (
      qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        .referralcode || ''
    )
  }

  render() {
    const { isSubmitting, hasError } = this.state
    return (
      <Form ref={this.form}>
        <Input
          className="w-full"
          autoFocus
          name="voucherCode"
          defaultValue={this.initValue}
          size={this.inputConfig}
          disabled={isSubmitting}
          hasError={hasError}
          placeholder="Promo Code"
          rightContent={
            <InputButton disabled={isSubmitting} onClick={this.handleSubmit}>
              {this.getStateIcon()}
            </InputButton>
          }
        />
        {this.errorMessage}
      </Form>
    )
  }
}
