import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import get from 'lodash/get'

import { Title, Content } from './styled'

const VenueBusinessHour = ({ openingHours = [], ...props }) => {
  return (
    <section {...props}>
      <Title>OPENING HOURS</Title>

      <div className="flex">
        <div className="mr-20">
          {openingHours.map(({ day }, index, items) => (
            <Fragment key={index}>
              {get(items, index - 1) &&
              get(items, `${index - 1}.day`) === get(items, `${index}.day`) ? (
                <Content key={index}>&nbsp;</Content>
              ) : (
                <Content key={index}>{day}</Content>
              )}
            </Fragment>
          ))}
        </div>
        <div>
          {openingHours.map(({ start, end }, index) => (
            <Content key={index}>{createRange(start, end)}</Content>
          ))}
        </div>
      </div>
    </section>
  )
}

const NBSP = ' '
const NOT_AVAILABLE = '00:00:00'
const CLOSED = 'closed'

const createRange = (start, end) => {
  if (start === NOT_AVAILABLE && end === NOT_AVAILABLE) {
    return CLOSED
  }

  const formatTime = time => moment(time, 'HH:mm:ss').format('h:mm A')

  const startsAt = formatTime(start)

  const endsAt = formatTime(end)

  const timeRange = `${startsAt} - ${endsAt}`.replace(/ /g, NBSP)

  return timeRange
}

VenueBusinessHour.propTypes = {
  openingHours: PropTypes.array,
}

export default VenueBusinessHour
