import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import xor from 'lodash/xor'
import isEmpty from 'lodash/isEmpty'
import { withProps } from 'recompose'
import Icon from 'components.v2/Icon/IconV2'

import { RadioProvider } from 'components/Radio'
import {
  OptionHeader,
  OptionSubHeader,
  ListContainer,
  OptionForm,
  Row,
} from './styled'
import Option from './Option'
import { getDefaultOption } from './helpers'

class OptionGroup extends React.Component {
  handleChange = event => {
    const { index, type, value } = this.props
    const changed = +event.target.value

    if (type === 'CHECKBOX') {
      this.props.onChange(index, xor(value, [changed]))
    } else {
      this.props.onChange(index, [changed])
    }
  }

  MaybeRadioProvider =
    this.props.type === 'RADIO'
      ? withProps({
          initialValue: !isEmpty(this.props.value)
            ? String(this.props.value)
            : String((getDefaultOption(this.props.options) || {}).id),
        })(RadioProvider)
      : Fragment

  render() {
    const {
      name,
      options,
      type,
      min,
      max,
      value,
      required,
      hasUnmetRequirements,
    } = this.props

    let optionLabel = required ? 'Required - ' : ''

    if (
      (min === null || Number(min) === 0) &&
      (max === null || Number(max) === 0)
    ) {
      optionLabel = null
    } else if (min === max) {
      optionLabel += `Choose ${min}`
    } else if (Number(min) && Number(max)) {
      optionLabel += `Choose ${min} to ${max}`
    } else {
      optionLabel += `Choose ${
        Number(min) ? `at least ${min}` : `up to ${max}`
      }`
    }

    return (
      <OptionForm onChange={this.handleChange}>
        <OptionHeader>
          <Row>
            <span>{name}</span>
            {optionLabel ? (
              <OptionSubHeader error={hasUnmetRequirements}>
                {optionLabel}
              </OptionSubHeader>
            ) : null}
          </Row>
          {optionLabel ? (
            <Icon
              style={{ float: 'right', marginLeft: 'auto' }}
              src="InfoModifier.svg"
            />
          ) : null}
        </OptionHeader>
        <ListContainer>
          <this.MaybeRadioProvider>
            {options.map(option => (
              <Option
                value={value}
                key={option.id}
                group={name}
                {...option}
                type={type}
              />
            ))}
          </this.MaybeRadioProvider>
        </ListContainer>
      </OptionForm>
    )
  }
}

OptionGroup.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  options: PropTypes.array,
  type: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  min: PropTypes.oneOfType([null, PropTypes.number]),
  max: PropTypes.oneOfType([null, PropTypes.number]),
  hasUnmetRequirements: PropTypes.bool,
}

export default OptionGroup
