import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import injectableStyles from 'utils/injectableStyles'

const defaultComponents = {
  Discount: styled.section`
    text-align: center;
    padding: 6px 15px;
    background-color: rgba(46, 154, 154, 0.1);
    color: #2e9a9a;
    font-size: 13px;

    & + & {
      padding-top: 0px;
    }

    &:not(:last-of-type) {
      padding-bottom: 2px;
    }
  `,
}

export const discountListFactory = injectableStyles(({ Discount }) => {
  const DiscountList = ({ discounts, ...props }) => (
    <Fragment>
      {Array.isArray(discounts) &&
        discounts.map(x => (
          <Discount key={shortid.generate()} {...props}>
            {x.name}
          </Discount>
        ))}
    </Fragment>
  )

  DiscountList.propTypes = {
    discounts: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }

  return DiscountList
}, defaultComponents)

export default discountListFactory()
