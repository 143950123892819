import React, { useState } from 'react'
import { Card, CardBody } from 'components.v2/Card'
import { getError } from 'utils/get-message'
import { NewsCorpForm } from './styled'
import { subscribe } from 'api/subscriptions'
import Input, { InputButton } from 'components.v2/Input'
import Spinner from 'components.v2/Spinner'
import Text from 'components.v2/Text'
import withNotifications from 'enhancers/withNotifications'

function NewsCorp({ notify, type = 'NewsCorp' }) {
  const [email, setEmail] = useState('')
  const [hasError, setHasError] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  async function handleSubmit(event) {
    event.preventDefault()
    try {
      if (isSubmitting) return

      setSubmitting(true)
      setHasError(false)

      await subscribe({
        type,
        subscriber: {
          email,
        },
      })

      setSubmitting(false)

      notify({
        type: 'success',
        message: 'Subscription was successfully sent',
      })

      setEmail('')
    } catch (err) {
      setSubmitting(false)
      setHasError(true)
      notify({
        type: 'error',
        message: getError(err),
      })
    }
  }

  function getButtonText() {
    return isSubmitting ? <Spinner variant="alt-primary" /> : 'Notify Me'
  }

  return (
    <Card>
      <CardBody>
        <NewsCorpForm onSubmit={handleSubmit}>
          <div className="NewsCorp__Left">
            <Text className="NewsCorp__Text" variant="h2">
              Want exclusive offers & deals?
            </Text>
            <Text className="NewsCorp__Text" variant="body">
              Enter your email and we'll send you exclusive
              <br />
              offers and deals straight to your inbox.
            </Text>
          </div>
          <div className="NewsCorp__Right">
            <Input
              className="w-full"
              name="email"
              size="regular"
              value={email}
              disabled={isSubmitting}
              hasError={hasError}
              onChange={e => {
                setEmail(e.target.value)
              }}
              placeholder="*Email"
              type="email"
              required
              rightContent={
                <InputButton
                  className="NewsCorp__Btn"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  {getButtonText()}
                </InputButton>
              }
            />
          </div>
        </NewsCorpForm>
      </CardBody>
    </Card>
  )
}

export default withNotifications(NewsCorp)
