import React from 'react'
import styled from 'styled-components'
import GoogleMap from './GoogleMap'
import { mobileFirstMedia as media } from 'utils/media'
import { compose, defaultProps } from 'recompose'
import { withGoogleMap } from 'react-google-maps'

const MapContainer = styled.div`
  height: 130px;

  ${media.screen`
    height: 250px;
  `}
`

export default compose(
  defaultProps({
    containerElement: <MapContainer />,
    mapElement: <div style={{ height: `100%` }} />,
    loadingElement: <div />
  }),
  withGoogleMap
)(GoogleMap)
