// const EARTH_RADIUS = 6371;

const EARTH_RADIUS = 6378137

const rad = x => (x * Math.PI) / 180

export const calculateDistance = (posA, posB) => {
  if (!posA || !posB) {
    return 0
  }

  const dLat = rad(posB.lat - posA.lat)
  const dLong = rad(posB.lng - posA.lng)
  const rads =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(posA.lat)) * Math.cos(rad(posB.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2)
  return Math.round(EARTH_RADIUS * 2 * Math.atan2(Math.sqrt(rads), Math.sqrt(1 - rads))) // returns the distance in meter
}

export const getAddressString = address => {
  if (!address) {
    return ''
  }
  const { address1 = '', address2 = '', suburb = '', state = '', postcode = '' } = address
  let addressString = address1
  if (address2) {
    addressString += ` ${address2}`
  }

  return `${addressString} ${suburb}, ${state} ${postcode}`
}

export const getSuburbFromUrl = urlString => {
  if (urlString) {
    const arr = urlString.split('-').map(str => str.charAt(0).toUpperCase() + str.slice(1))
    return arr.reduce((acc, curr) => acc + curr + ' ', '')
  }
  return ''
}

export default getAddressString
