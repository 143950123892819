import React from 'react'
import ContentLoader from 'react-content-loader'
import { MobileScreen, DesktopScreen } from 'utils/screen'

export default props => (
  <div {...props}>
    <DesktopScreen>
      <ContentLoader
        height={255}
        width={370}
        style={{ display: 'block', minWidth: 0, height: 'auto' }}
      >
        <rect y="0" rx="0" ry="0" width="370" height="200" />
        <rect y="216" rx="0" ry="0" width="66%" height="16" />
        <rect y="240" rx="0" ry="0" width="80%" height="11" />
      </ContentLoader>
    </DesktopScreen>
    <MobileScreen>
      <ContentLoader
        height={230}
        width={300}
        style={{ display: 'block', minWidth: 0, height: 'auto' }}
      >
        <rect y="0" rx="0" ry="0" width="300" height="180" />
        <rect y="198" rx="0" ry="0" width="66%" height="14" />
        <rect y="220" rx="0" ry="0" width="80%" height="10" />
      </ContentLoader>
    </MobileScreen>
  </div>
)
