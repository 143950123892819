import React from 'react'
import PropTypes from 'prop-types'

import enhance from './LocationSearchBarContainer'
import Spinner from 'components.v2/Spinner'
import Text from 'components.v2/Text'

const locationSearchBarFactory = ({
  Container,
  AutoCompleteContainer,
  AutoCompleteItem,
  ClearButton,
  CurrentLocation,
  CurrentLocationContainer,
  Icon,
  Input,
  LocationDesc,
  LocationIcon,
  LocationName,
  SearchButton,
  TargetIcon,
}) => {
  const LocationSearchBar = ({
    autocompleteItems,
    cta,
    handleAutocompleteSelect,
    handleInputBlur,
    handleInputChange,
    handleInputClear,
    handleInputFocus,
    handleSearch,
    handleUseCurrentLocation,
    hasFocus,
    inputValue,
    locationName,
    isDeterminingLocation,
    isCurrentLocation,
    rightButton,
    defaultBorderStyle,
    inputRef,
  }) => {
    const fallbackInputValue = isCurrentLocation
      ? 'Current Location'
      : locationName
    const shouldShowClear =
      hasFocus && !isDeterminingLocation && inputValue !== fallbackInputValue

    return (
      <Container defaultBorderStyle={defaultBorderStyle} hasFocus={hasFocus}>
        <LocationIcon />
        <Input
          value={isDeterminingLocation ? '' : inputValue}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          ref={inputRef}
          placeholder={
            isDeterminingLocation
              ? 'Identifying current location...'
              : 'Enter a location or suburb'
          }
          disabled={isDeterminingLocation}
        />

        {shouldShowClear && (
          <ClearButton onMouseDown={handleInputClear}>
            <Icon
              name="fas fa-times-circle"
              color="grey"
              size={12}
              width={38}
              height={38}
            />
          </ClearButton>
        )}

        {!cta && isDeterminingLocation && <Spinner className="mr-10" />}

        {cta && (
          <SearchButton
            onClick={ev => {
              inputRef.current && inputRef.current.focus()
              handleSearch(ev)
            }}
            loading={isDeterminingLocation}
          >
            <Text color="white" className="px-15 xl:px-30">
              Find&nbsp;Food
            </Text>
          </SearchButton>
        )}

        {hasFocus && !isDeterminingLocation && (
          <AutoCompleteContainer>
            <CurrentLocationContainer onMouseDown={handleUseCurrentLocation}>
              <TargetIcon />
              <CurrentLocation>Use current location</CurrentLocation>
            </CurrentLocationContainer>

            {autocompleteItems.map(({ id, structured_formatting: opt }) => (
              <AutoCompleteItem
                key={id}
                onMouseDown={handleAutocompleteSelect.bind(null, id)}
              >
                <LocationName>{opt.main_text}</LocationName>
                <LocationDesc>{opt.secondary_text}</LocationDesc>
              </AutoCompleteItem>
            ))}
          </AutoCompleteContainer>
        )}

        {rightButton}
      </Container>
    )
  }

  LocationSearchBar.propTypes = {
    autocompleteItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        place_id: PropTypes.string.isRequired,
        structured_formatting: PropTypes.shape({
          main_text: PropTypes.string.isRequired,
          secondary_text: PropTypes.string,
        }),
      }),
    ).isRequired,
    cta: PropTypes.bool,
    handleAutocompleteSelect: PropTypes.func.isRequired,
    handleInputBlur: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleInputClear: PropTypes.func.isRequired,
    handleInputFocus: PropTypes.func.isRequired,
    handleSearch: PropTypes.func,
    handleUseCurrentLocation: PropTypes.func.isRequired,
    hasFocus: PropTypes.bool.isRequired,
    inputValue: PropTypes.string.isRequired,
    locationName: PropTypes.string.isRequired,
    isDeterminingLocation: PropTypes.bool.isRequired,
    isCurrentLocation: PropTypes.bool.isRequired,
    rightButton: PropTypes.node,
  }

  return enhance(LocationSearchBar)
}

export default locationSearchBarFactory
