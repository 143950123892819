import styled from 'styled-components'
import Icon from '../Icon'

export const Checkmark = styled(Icon)`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
  border: 1.5px solid
    ${props =>
      props.theme.colors[props.highlighted ? props.color : props.baseColor]};
  > i {
    transition: opacity 0.15s ease-in-out;
    opacity: ${props => (props.checked ? 1 : 0)};
  }
`

export const CheckInput = styled.input`
  position: absolute;
  opacity: 0;

  checked ~ ${Checkmark} {
  }
`

export const CheckboxContainer = styled.label`
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  margin-right: 12px;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  :hover {
    ${Checkmark} {
      border-color: ${props => props.theme.colors[props.color]};
    }
  }
`
