import React, { Fragment } from 'react'
import styled from 'styled-components'
import PT from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import isNode from 'detect-node'

/**
 * Notes:
 * - last link is always assumed to be active, uses `"a"` element instead of `react-router-doms`'s `Link`, and has the current location as its `href`.
 * - it passes all other props to its container `styled.div` element
 */
const Breadcrumbs = ({ color, links, ...props }) => (
  <Container color={color} {...props}>
    {links.map((link, i) =>
      i === (links.length - 1) // is last or is active
        ? <Link key={i} href={isNode ? '#' : window.location.href}>{link.text}</Link>
        : (
          <Fragment key={i}>
            <Link as={link.disabled ? 'span' : RouterLink} to={link.url}>{link.text}</Link>
            <Divider>/</Divider>
          </Fragment>
        ))}
  </Container>
)

const Container = styled.div`
  &, & a {
    color: ${p => p.theme.colors[p.color] || p.color || p.theme.colors.textSecondary};
  }
`

const Link = styled.a`
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;

  :last-child {
    font-weight: 300;
  }
`

const Divider = styled.span`
  padding: 0 8px;
  font-size: 12px;
`

Breadcrumbs.propTypes = {
  /** Sets the color of text. Either CSS color value or key of `theme.colors` */
  color: PT.string,
  links: PT.arrayOf(PT.shape({
    text: PT.string.isRequired,
    url: PT.string,
    disabled: PT.bool
  })).isRequired
}

export default Breadcrumbs
