import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import { selectPaymentBalance } from 'store/payment'

import Balance from './Balance'

export default connect(
  createSelector(
    selectPaymentBalance,
    balance => {
      return {
        balance,
      }
    },
  ),
  null,
)(Balance)
