import styled from 'styled-components'
import { mobileFirstMedia, media } from 'utils/media'
import { factory as locationSearchBarFactory } from 'common/LocationSearchBar'
import { factory as desktopContainerFactory } from 'layouts/DesktopContainer'

export const StaticHeaderContainer = styled.header`
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  background-color: ${props => props.theme.colors.primary};
  padding: 15px;

  ${mobileFirstMedia.screen`
    padding: 0;
  `}
`

export const SearchBarsContainer = styled.header`
  display: flex;
  flex-wrap: wrap;
  margin: -7.5px;
`
export const SearchBarContainer = styled.div`
  padding: 7.5px;
  flex: 0 0 50%;
  max-width: 350px;

  ${media.phone`
    flex: 0 0 100%;
    max-width: 100%;
  `}
`

export const LocationSearchBar = locationSearchBarFactory({
  Container: x => styled(x)`
    max-width: 100%;
    border-color: transparent;
  `,
  Input: x => styled(x)`
    line-height: 40px;
  `,
  AutoCompleteContainer: x => styled(x)`
    margin-top: -1px;
    border-top: 1px solid #e1e1e1;
  `,
  LocationIcon: x => styled(x).attrs({
    fill: '#f47325',
    stroke: 'none',
  })`
    width: 40px;
  `,
  TargetIcon: x => styled(x).attrs({
    fill: '#f47325',
    stroke: 'none',
  })`
    width: 40px;
  `,
})

export const DesktopContainer = desktopContainerFactory({
  Container: x => styled(x)`
    padding: 15px 0;

    ${media.screen`
      padding: 15px 30px;
    `}

    ${media.tablet`
      padding: 15px 15px;
    `}
  `,
})

// export const DEPRECATED_HeaderContainer = styled.header`
//   background-color: ${props => props.theme.colors.orange};
//   height: 125px;
//   padding: 15px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-between;
// `;
