import React, { Fragment } from 'react'
import PT from 'prop-types'
import { connect } from 'react-redux'
import { Waypoint } from 'react-waypoint'
import { compose, withStateHandlers } from 'recompose'

import CuisineSearchBar from 'common/CuisineSearchBar'
import { MobileScreen, DesktopScreen } from 'utils/screen'
import { selectVisibleModal, types } from 'store/modals'
import StaticSearchBars from './StaticSearchBars'

import {
  SearchBarsContainer,
  StaticHeaderContainer,
  LocationSearchBar,
  DesktopContainer,
  SearchBarContainer,
} from './styled'

const withContainer = compose(
  connect(state => ({
    shouldShowSticky: selectVisibleModal(state) !== types.SEARCH,
  })),
  withStateHandlers(
    { isSticky: false },
    {
      handleWaypointEnter: () => () => ({ isSticky: false }),
      handleWaypointLeave: () => () => ({ isSticky: true }),
    },
  ),
)

const SearchBarHeader = ({
  shouldShowSticky,
  isSticky,
  handleWaypointEnter,
  handleWaypointLeave,
}) => {
  const searchBarsJsx = (
    <DesktopContainer background="primary">
      <SearchBarsContainer>
        <SearchBarContainer>
          <LocationSearchBar />
        </SearchBarContainer>
        <SearchBarContainer>
          <CuisineSearchBar />
        </SearchBarContainer>
      </SearchBarsContainer>
    </DesktopContainer>
  )

  return (
    <Fragment>
      <Waypoint
        topOffset="72px"
        onEnter={handleWaypointEnter}
        onLeave={handleWaypointLeave}
      >
        <div>{searchBarsJsx}</div>
      </Waypoint>

      {isSticky && shouldShowSticky && (
        <StaticHeaderContainer>
          <MobileScreen>
            <StaticSearchBars />
          </MobileScreen>
          <DesktopScreen>{searchBarsJsx}</DesktopScreen>
        </StaticHeaderContainer>
      )}
    </Fragment>
  )
}

SearchBarHeader.propsTypes = {
  shouldShowSticky: PT.bool.isRequired,
  isSticky: PT.bool.isRequired,
  handleWaypointEnter: PT.func.isRequired,
  handleWaypointLeave: PT.func.isRequired,
}

export default withContainer(SearchBarHeader)
