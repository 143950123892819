import React, { Fragment } from 'react'
import PT from 'prop-types'
import { compose, withState } from 'recompose'
import Clamp from 'react-lines-ellipsis'
import Paragraphs from 'components.v2/Paragraphs'
import Icon from 'components/Icon.v2'

/** Renders an expandable excerpt. Other props are passed to parent `<div>` */
const Excerpt = ({
  children,
  isOpen,
  setIsOpen,
  clampRef,
  setClampRef,
  className = '',
  ...props
}) => {
  const shouldShowBtn = clampRef && clampRef.isClamped()

  return (
    <div className={`flex items-end ${className}`} {...props}>
      {isOpen ? (
        <Fragment>
          <div className='flex-grow pr-10'>
            <Paragraphs method='brbr'>{children}</Paragraphs>
          </div>
          <button onClick={() => setIsOpen(false)}>
            <Icon type='angleUp' fill='textPrimary' size={12} />
          </button>
        </Fragment>
      ) : (
        <Fragment>
          <Clamp
            text={children}
            ref={setClampRef}
            maxLine={3}
            className={`flex-grow ${shouldShowBtn ? 'pr-10' : 'pr-25'}`}
          />
          {shouldShowBtn && (
            <button onClick={() => setIsOpen(true)}>
              <Icon type='angleDown' fill='textPrimary' size={15} />
            </button>)}
        </Fragment>
      )}
    </div>
  )
}

Excerpt.propTypes = {
  children: PT.string.isRequired,
  /** If any, it is concatenated to base `className` */
  className: PT.string,
  /** @ignore */ isOpen: PT.bool.isRequired,
  /** @ignore */ setIsOpen: PT.func.isRequired
}

export default compose(
  withState('isOpen', 'setIsOpen', false),
  withState('clampRef', 'setClampRef', null)
)(Excerpt)
