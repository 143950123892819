import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import get from 'lodash/get'

import { isMultiVendor } from 'utils/multiVendor'
import getHistory from 'services/history'
import { selectPrecinct } from 'store/precinct/selectors'

export default compose(
  connect(state => ({
    precinct: selectPrecinct(state),
    hasRehydrated: get(state, 'rehydration.hasRehydrated'),
  })),

  lifecycle({
    componentDidMount() {
      const { precinct, hasRehydrated } = this.props
      if (hasRehydrated && isMultiVendor()) {
        get(precinct, 'tag')
          ? getHistory().push(`/precinct/${get(precinct, 'tag')}`)
          : getHistory().push(`/precinct`)
      }
    },

    componentDidUpdate(prev) {
      const { precinct } = this.props
      if (
        get(this.props, 'hasRehydrated') !== get(prev, 'hasRehydrated') &&
        isMultiVendor()
      ) {
        get(precinct, 'tag')
          ? getHistory().push(`/precinct/${get(precinct, 'tag')}`)
          : getHistory().push(`/precinct`)
      }
    },
  }),
)
