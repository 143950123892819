import React from 'react'
import { arrayOf, shape, number, bool, string } from 'prop-types'
import times from 'lodash/times'
import { withRouter } from 'react-router'

import { VenueCardLoader } from 'common/VenueCard'
import events from 'const/analytics-events'
import VenueCard from 'common/VenueCard'
import withAnalytics from 'enhancers/withAnalytics'

const VenueCardTracked = withAnalytics()(props => (
  <div
    onClick={props.onClick}
    className="pt-30 xl:pt-40 px-15 w-full sm:w-1/2 lg:w-1/3"
  >
    {props.children}
  </div>
))

const VenueGrid = ({ venues, isLoading, className, location, ...props }) => {
  return (
    <div className={`flex flex-wrap -mt-30 xl:-mt-40 ${className}`} {...props}>
      {!venues.length &&
        isLoading &&
        times(6, i => (
          <div
            className="pt-30 xl:pt-40 px-15 w-full sm:w-1/2 lg:w-1/3"
            key={i}
          >
            <VenueCardLoader />
          </div>
        ))}

      {!!venues.length &&
        venues.map(v => (
          <VenueCardTracked
            key={v.id}
            analytics={[
              {
                eventType: 'onClick',
                options: {
                  ...events['RESULT_CLICK_VENUE'],
                  label: String(v.id),
                },
              },
            ]}
          >
            <VenueCard {...v} locationRouter={location} />
          </VenueCardTracked>
        ))}
    </div>
  )
}

VenueGrid.propTypes = {
  className: string,
  isLoading: bool,
  venues: arrayOf(
    shape({
      id: number.isRequired,
    }),
  ).isRequired,
}

export default withRouter(VenueGrid)
