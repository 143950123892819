import { Link } from 'react-router-dom'
import colors from 'theme/colors'
import styled from 'styled-components'
import { mobileFirstMedia as media } from 'utils/media'

export const Divider = styled.hr`
  border-bottom: solid 1px ${colors.gray5};
`

export const AvatarWrapper = styled.div`
  text-align: center;
  margin-bottom: 30px;
`

export const PoinsMenuInner = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`

export const AvatarImg = styled.div`
  margin: 0 auto;
  width: ${props => props.width || `${100}px`};
  height: ${props => props.height || `${100}px`};
  border-radius: 50%;
  border: solid 1px rgba(0,0,0,0.1);
  background-color: rgba(0,0,0,0.1);
  background-image: url('${props => props.src}');
  background-size: cover;
  background-position: center;
`

export const AvatarEmpty = styled.div`
  width: ${props => props.width || `${100}px`};
  height: ${props => props.height || `${100}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors['primary']};
  color: white;
  margin: auto;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
`

export const AvatarTitle = styled.h3`
  margin-top: 13px;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  ${media.screen`
    font-size: 20px;
    line-height: 30px;
  `}
`

export const AvatarEdit = styled(Link)`
  color: ${colors['darkGrey']};
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;

  ${media.screen`
    font-size: 12px;
    line-height: 20px;
  `}

  &:hover,
  &:visited {
    text-decoration: none;
  }
`

export const AvatarDate = styled.div`
  color: ${colors['darkGrey']};
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1px;
  font-weight: 300;
  text-transform: uppercase;

  ${media.screen`
    font-size: 12px;
    line-height: 20px;
  `}

  &:hover,
  &:visited {
    text-decoration: none;
  }
`

export const MenuWrap = styled.div`
  padding: 25px;
`

export const MenuList = styled.ul`
  margin: 0;
  align-self: flex-start;
`

export const MenuItem = styled.li`
  cursor: pointer;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors['darkGrey']};

  :last-child {
    margin-bottom: 0;
  }

  a {
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    color: ${colors['darkGrey']};
    flex: 1;
    display: block;
    &:hover,
    &:visited {
      text-decoration: none;
    }
  }
`
export const MenuIcon = styled.div`
  margin-right: 20px;
  width: 25px;
  height: 25px;
  text-align: center;

  div {
    display: inline;
  }

  svg {
    width: 22px;
    height: 22px;
  }
`

export const MenuLogout = styled.a`
  font-size: 15px;
  display: block;
  padding: 25px 25px 0 25px;
  text-decoration: none;
  font-weight: 500;
  color: ${colors['textLight']};
  cursor: pointer;

  &:hover,
  &:visited {
    text-decoration: none;
  }
`
