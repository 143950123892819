import injectableStyles from 'utils/injectableStyles'
import Icon from 'components/Icon'
import IconV2 from 'components/Icon.v2'

import * as styledComponents from './styled'
import LocationSearchBarFactory from './LocationSearchBar'

const defaultStyledCmpts = {
  ...styledComponents,
  IconV2,
  Icon
}

const factory = injectableStyles(LocationSearchBarFactory, defaultStyledCmpts)

export default factory()
export { factory }
