import React from 'react'
import FormError from 'components.v2/FormError'

/**
 * Gets formik errors object and map it to array of string messages
 *
 * @param {Object} errors
 *
 * @returns {Array}
 */
export const formErrors = errors => Object.values(errors).map(err => err)

/**
 * Render errors to form error component
 *
 * @param {Array} messages
 *
 * @returns {Array}
 */
export const renderMessages = messages =>
  messages.map((msg, i) => <FormError key={i}>{msg}</FormError>)
