import styled from 'styled-components'

import { media } from 'utils/media'
import Icon from 'components/Icon.v2'
import Button from 'components.v2/Button'

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: ${props => {
    if (props.hasFocus) {
      return '1px solid #f47325'
    } else {
      return props.defaultBorderStyle || '1px solid rgba(0, 0, 0, 0.1)'
    }
  }};
  width: 100%;
  max-width: 450px;
  background-color: #ffffff;
`

export const Input = styled.input`
  flex: 1;
  outline: none;
  border: none;
  padding: 0;
  font-size: 16px;
  line-height: 48px;
  font-weight: 300;
  color: #333333;
  overflow: hidden;

  ::placeholder {
    color: #999999;
  }
`

export const ClearButton = styled.div`
  flex: 0 0 auto;
  cursor: pointer;
`

export const SearchButton = styled(Button).attrs({ variant: 'primary' })`
  display: flex;
  flex: 0 0 auto;
  padding: ${p => `calc((${p.large ? '50px' : '40px'} - 1.25em) / 2) 2px`};
  height: 50px;
  background-color: #f47325;
  cursor: pointer;
  margin: -1px -1px -1px 0;
`

export const AutoCompleteContainer = styled.ul`
  z-index: 1;
  position: absolute;
  top: calc(100% + 1px);
  left: -1px;
  right: -1px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`

export const AutoCompleteItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid transparent;
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
  padding: 0 15px;
  height: 70px;
  cursor: pointer;

  :last-child:not(:only-child) {
    border-bottom: 0;
  }

  ${media.screen`
    height: 60px;
  `}
`

export const CurrentLocationContainer = styled(AutoCompleteItem)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;

  :only-child {
    border-color: transparent;
  }
`

export const LocationIcon = styled(Icon).attrs({
  type: 'location',
  size: 20,
  stroke: '#979797',
  fill: 'none',
})`
  width: 50px;

  ${media.screen`
      width: 45px;
    `}
`

export const TargetIcon = styled(Icon).attrs({
  type: 'target',
  size: 18,
  fill: '#f47325',
})`
  width: 50px;

  ${media.screen`
    width: 45px;
  `}
`

export const CurrentLocation = styled.div`
  font-size: 14px;
  line-height: 125%;
  color: #333333;
`

export const LocationName = styled(CurrentLocation)`
  margin-bottom: 5px;
  font-weight: 300;
`

export const LocationDesc = styled(CurrentLocation)`
  font-weight: 300;
  color: #999999;
`
