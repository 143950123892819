import React from 'react'
import PropTypes from 'prop-types'

import SearchBarHeader from './SearchBarHeader'
import PageLayout from '../PageLayout'

class SearchBarLayout extends React.Component {
  render() {
    const { children, toggleCuisineModal, title } = this.props

    return (
      <PageLayout title={title}>
        <SearchBarHeader toggleModal={toggleCuisineModal} />
        {children}
      </PageLayout>
    )
  }
}

SearchBarLayout.propTypes = {
  toggleCuisineModal: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
}

export default SearchBarLayout
