import React from 'react'
import PropTypes from 'prop-types'
import { Title, ContentSection, Content } from './styled'

const VenuePhone = ({ phone, ...props }) => {
  return (
    <section {...props}>
      <Title>PHONE NUMBER</Title>
      <ContentSection>
        <Content>{phone}</Content>
      </ContentSection>
    </section>
  )
}

VenuePhone.propTypes = {
  phone: PropTypes.string
}

export default VenuePhone
