import styled from 'styled-components'

export const PlaceSelectorContainer = styled.div`
  padding: 20px 0;
`

export const CitiesList = styled.div`
  margin-top: 20px;
`

export const DesktopCityList = styled.div`
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CityLinkFactory = el => {
  const styledEl = typeof el === 'string' ? styled[el] : styled(el)
  const Dt = styledEl`
    cursor: pointer;
    margin-right: 20px !important;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    color: ${p => (p['isactive'] === 'true' ? '#333333' : '#CCCCCC')}
  `
  const Mb = styledEl`
    display: block;
    color: ${p => (p.isselected === 'true' ? '#333333' : '#CCCCCC')};
    font-size: 18px;
    line-height: 150%;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;

    :not(:first-of-type) {
      margin-top: 5px;
    }
  `

  return { Dt, Mb }
}
