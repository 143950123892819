import React from 'react'
import PT from 'prop-types'

import {
  ReadyToOrderContainer,
  Heading,
  LocationSearchBar,
  Copy,
} from './styled'

const DEFAULT_HEADING = 'Ready to order?'
const DEFAULT_COPY =
  'Browse local cafes and restaurants by entering a location or suburb below'

const ReadyToOrder = ({ heading = DEFAULT_HEADING, copy = DEFAULT_COPY }) => (
  <ReadyToOrderContainer className="py-50 px-15 xl:px-25">
    <Heading>{heading}</Heading>
    <Copy>{copy}</Copy>
    <LocationSearchBar placement="subhero" cta />
  </ReadyToOrderContainer>
)

ReadyToOrder.propTypes = {
  heading: PT.string,
  copy: PT.string,
}

export default ReadyToOrder
