import React from 'react'
import { LoadingScreen } from './styled'
import Spinner from 'components.v2/Spinner'

export default () => {
  return (
    <LoadingScreen>
      <Spinner className="mx-auto" width={70} height={70} thickness={5} />
    </LoadingScreen>
  )
}
