import React from 'react'
import PT from 'prop-types'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import VenueStatus from './VenueStatus'

class VenueStatusContainer extends React.Component {
  static propTypes = {
    info: PT.object,
    isLoading: PT.bool,
  }

  render() {
    const { info, isLoading } = this.props
    const { status, openingHours } = info
    const show = status === 'CLOSED' || status === 'OFFLINE'
    if (!show || isLoading) return null
    return (
      <VenueStatus
        {...info.address || {}}
        status={status}
        openingHours={openingHours}
        isOpen
      />
    )
  }
}

export default connect(
  createSelector(
    state => state.vendor.info,
    ({ info, isLoading }) => ({ info, isLoading }),
  ),
  null,
)(VenueStatusContainer)
