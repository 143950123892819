import React, { Fragment } from 'react'
import { string, bool } from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import styled from 'styled-components'

import imageURL from 'utils/imageURL'
import media from 'utils/media'

export const BlankDiv = styled.div`
  margin-bottom: 40px;
  ${media.screen`
    display:none;
  `}
`

export const ImageContainer = styled.div`
  background-image: url('${p => p.src}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 600px;
  height: 600px;

  border-radius: 12px 0px 0px 12px;

  ${media.screen`
    width: 100vw;
    border-radius: 0px;
    height: 375px;
    display: none;
  `}

  ${media.phone`
    display: none;
  `}
  ${media.tablet`
    display: none;
  `}
`

class VenueCategoryItemImage extends React.Component {
  static propTypes = {
    status: string,
    shouldUseCoverImg: bool,
    hasStatus: bool,
  }

  DIMENSIONS = {
    width: 600,
    height: 600,
  }

  DEFAULT_RATIOS = {
    screen: [600, 200],
    phone: [375, 240],
  }

  state = {
    hasError: false,
    imageSrc: null,
    isLoadingImage: false,
  }

  componentDidMount = () => {
    const { menu, imageStatus } = this.props

    if (!isEmpty(menu) && !imageStatus.isLoadingImage) {
      this.loadImage(menu)
    }
    return true
  }

  shouldComponentUpdate = nextProps => {
    const { menu, imageStatus } = nextProps

    if (
      !isEmpty(menu) &&
      !imageStatus.isLoadingImage &&
      get(nextProps, 'menu.id') !== get(this.props, 'menu.id')
    ) {
      this.loadImage(menu)
    }

    return true
  }

  componentWillUnmount = () => {
    this.image = null
  }

  loadImage = menu => {
    this.props.setImageStatus({
      isLoadingImage: true,
      hasError: false,
      imageSrc: null,
    })
    const imageUrl = this.getImageUrl(menu)
    this.image = new Image()
    this.image.src = imageUrl
    this.image.onload = () => {
      this.props.setImageStatus(
        {
          imageSrc: imageUrl,
          isLoadingImage: false,
        },
        () => {
          this.image = null
        },
      )
    }
    this.image.onerror = () => {
      this.props.setImageStatus({
        hasError: true,
        isLoadingImage: false,
      })
    }
  }

  getImagePath = menu => {
    return get(menu, 'custom', '')
  }

  getImageSrc() {
    return this.props.imageStatus.hasError
      ? ''
      : this.props.imageStatus.imageSrc
  }

  getImageUrl(menu) {
    return imageURL(this.getImagePath(menu), this.DIMENSIONS)
  }

  render() {
    const { menu } = this.props

    if (isEmpty(menu) || isEmpty(this.getImageSrc())) {
      return <BlankDiv />
    }

    return (
      <Fragment>
        <ImageContainer src={this.getImageSrc()} alt={get(menu, 'name', '')} />
      </Fragment>
    )
  }
}

export default VenueCategoryItemImage
