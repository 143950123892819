import React from 'react'
import PropTypes from 'prop-types'

import enhance from './CuisineSearchBarContainer'
import passInnerRef from 'utils/passInnerRef'

const CuisineSearchBarFactory = ({
  ClearButton,
  Input,
  CrossIcon,
  SearchIcon,
  Container: PassedContainer
}) => {
  const Container = passInnerRef(PassedContainer)
  const CuisineSearchBar = ({
    handleInputFocus,
    inputValue,
    rightButton
  }) =>
    <Container>
      <SearchIcon />
      <Input
        value={inputValue}
        onFocus={handleInputFocus}
        placeholder={'Search for restaurant or cuisine'}
        readOnly
      />
      {rightButton}
    </Container>

  CuisineSearchBar.propTypes = {
    handleInputFocus: PropTypes.func.isRequired,
    inputValue: PropTypes.string.isRequired,
    rightButton: PropTypes.node
  }

  return enhance(CuisineSearchBar)
}

export default CuisineSearchBarFactory
