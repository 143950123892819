import React from 'react'
import { string, bool } from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import styled from 'styled-components'

import imageURL from 'utils/imageURL'
import media from 'utils/media'
import ReactContentLoader from 'react-content-loader'

export const BlankDiv = styled.div`
  margin-bottom: 40px;
`
export const BoxShadow = styled.div`
  height: 119px;
  width: 100vw;
  transform: matrix(1, 0, 0, -1, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(217, 217, 217, 0) 100%
  );
`

export const ImageContainer = styled.div`
  background-image: url('${p => p.src}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 600px;
  height: 600px;
  display: none;

  transition: height 300ms;
  -webkit-transition: height 300ms;

  ${media.screen`
    width: 100vw;
    border-radius: 0px;
    height: 180px;
    display: flex;
    align-items: flex-end;
  `}

  ${media.phone`
    display: flex;
    align-items: flex-end;
    height: 180px;
  `}


  ${media.tablet`
    display: flex;
    align-items: flex-end;
    height: 180px;
  `}

  &.expanded {
    height: 375px;
  }

  &.loading {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`

class NoImage extends React.Component {
  static propTypes = {
    status: string,
    shouldUseCoverImg: bool,
    hasStatus: bool,
  }

  DIMENSIONS = {
    width: 600,
    height: 600,
  }

  DEFAULT_RATIOS = {
    screen: [600, 200],
    phone: [375, 240],
  }

  state = {
    hasError: false,
    imageSrc: null,
    isLoadingImage: false,
    expanded: false,
  }

  componentDidMount = () => {
    const { menu, expand } = this.props

    if (!isEmpty(menu) && !this.state.isLoadingImage) {
      this.loadImage(menu)
    }

    if (this.state.expanded !== expand) {
      this.setState({
        ...this.state,
        expanded: expand,
      })
    }

    return true
  }

  shouldComponentUpdate = nextProps => {
    const { menu, expand } = nextProps

    if (
      !isEmpty(menu) &&
      !this.state.isLoadingImage &&
      !this.hasError &&
      get(nextProps, 'menu.id') !== get(this.props, 'menu.id')
    ) {
      this.loadImage(menu)
    }

    if (this.state.expanded !== expand) {
      this.setState({
        ...this.state,
        expanded: expand,
      })
    }

    return true
  }

  componentWillUnmount = () => {
    this.image = null
  }

  loadImage = menu => {
    this.setState({
      isLoadingImage: true,
      hasError: false,
      imageSrc: null,
    })
    const imageUrl = this.getImageUrl(menu)
    this.image = new Image()
    this.image.src = imageUrl
    this.image.onload = () => {
      this.setState(
        {
          imageSrc: imageUrl,
          isLoadingImage: false,
        },
        () => {
          this.image = null
        },
      )
    }
    this.image.onerror = () => {
      this.setState({
        hasError: true,
        isLoadingImage: false,
        imageSrc: null
      })
    }
  }

  getImagePath = menu => {
    return get(menu, 'custom', '')
  }

  getImageSrc() {
    return this.state.hasError ? '' : this.state.imageSrc
  }

  getImageUrl(menu) {
    return imageURL(this.getImagePath(menu), this.DIMENSIONS)
  }

  render = () => {
    const { menu, children, handleClick } = this.props

    const toggleExpand = () => {
      handleClick()
      this.setState({
        ...this.state,
        expanded: this.state.expanded,
      })
    }

    if (this.state.isLoadingImage && !this.state.hasError && !isEmpty(menu)) {
      return (
        <ImageContainer className="loading">
          <ReactContentLoader height="180" />
        </ImageContainer>
      )
    }
    if (isEmpty(menu) || isEmpty(this.getImageSrc())) {
      return children
    }

    return (
      <ImageContainer
        className={this.state.expanded ? 'expanded' : ''}
        onClick={toggleExpand}
        src={this.getImageSrc()}
      >
        <BoxShadow />
      </ImageContainer>
    )
  }
}

export default NoImage
