import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import compose from 'lodash/fp/compose'
import { setDisplayName, lifecycle } from 'recompose'
import get from 'lodash/get'
import PT from 'prop-types'

import {
  changeOrderQty,
  checkoutOrder,
  selectOrdersByVenueId,
  selectDiscountsByVenueId,
  selectIsChecking,
  selectFeesByVenueId,
  upsizeOrder,
} from 'pages/order/modules/order'
import {
  changeMultiVendorOrderQty,
  checkoutMultiVendorOrder,
  selectMultiVendorOrdersByVenueId,
  selectMultiVendorDiscountsByVenueId,
  selectMultiVendorIsChecking,
  selectMultiVendorFeesByVenueId,
  selectMultiVendorOrders,
  upsizeMultiVendorOrder,
} from 'pages/order/modules/multi.vendor.order'
import { selectInfoById } from 'pages/vendor/modules/info'
import { isMultiVendor as isMultiVendorEnv } from 'utils/multiVendor'
import { selectIsAuthenticated } from 'auth/modules/auth'
import { isWhiteLabel } from 'utils/whiteLabel'
import { getQueryParams } from 'utils/url'

const withOrderContainer = (config = {}) =>
  compose(
    setDisplayName('OrderContainer'),

    withRouter,

    connect(
      (state, props) => {
        const qstr = getQueryParams(props.history.location.search)
        const venueId = props.match.params.id
        const venue = selectInfoById(state, venueId)
        const isMultiVendor =
          (get(config, 'isMultiVendorOrders') || get(venue, 'isMultiVendor')) &&
          isMultiVendorEnv()

        const orderParty = get(state, `orderParty.parties.${venueId}`)
        const selectedState = {
          selectedOrders: isMultiVendor
            ? venueId
              ? selectMultiVendorOrdersByVenueId(state, venueId)
              : selectMultiVendorOrders(state)
            : selectOrdersByVenueId(state, venueId),
          discounts: isMultiVendor
            ? selectMultiVendorDiscountsByVenueId(state, venueId)
            : selectDiscountsByVenueId(state, venueId),
          isChecking: isMultiVendor
            ? selectMultiVendorIsChecking(state)
            : selectIsChecking(state),
          venue: venue,
          orderParty,
          isAuthenticated: selectIsAuthenticated(state),
          isGfoOrder:
            !(isMultiVendorEnv() || isWhiteLabel()) &&
            get(qstr, 'channel') === 'gfo_redirect',
        }

        if (get(config, 'shouldIncludeFees')) {
          selectedState.fees = isMultiVendor
            ? selectMultiVendorFeesByVenueId(state, venueId)
            : selectFeesByVenueId(state, venueId)
        }

        return selectedState
      },
      {
        changeOrderQty,
        checkoutOrder,
        changeMultiVendorOrderQty,
        checkoutMultiVendorOrder,
        upsizeOrder,
        upsizeMultiVendorOrder,
      },
      (state, actions, ownProps) => ({
        ...ownProps,
        ...state,
        ...actions,
        selectedOrders: state.selectedOrders.map(order => {
          if (get(config, 'isMultiVendorOrderPage')) {
            return {
              ...order,
              orders: order.orders.map(ord => ({
                ...ord,
                changeOrderQty: qty =>
                  actions.changeMultiVendorOrderQty(
                    ord,
                    get(order, 'vendorInfo.id'),
                    qty,
                    { checkout: true },
                  ),
                upsizeOrder: (serialisedOptions, index) =>
                  actions.upsizeMultiVendorOrder({
                    venueId: get(order, 'vendorInfo.id'),
                    id: ord.id,
                    serialisedOptions,
                    opts: {
                      checkout: true,
                    },
                    index,
                  }),
              })),
            }
          }

          return {
            ...order,
            changeOrderQty: qty =>
              get(state, 'venue.isMultiVendor') && isMultiVendorEnv()
                ? actions.changeMultiVendorOrderQty(
                    order,
                    ownProps.match.params.id,
                    qty,
                    { checkout: get(config, 'checkoutOnQuantityChange') },
                  )
                : actions.changeOrderQty(order, ownProps.match.params.id, qty, {
                    checkout: get(config, 'checkoutOnQuantityChange'),
                  }),
            upsizeOrder: (serialisedOptions, index) =>
              get(state, 'venue.isMultiVendor') && isMultiVendorEnv()
                ? actions.upsizeMultiVendorOrder({
                    venueId: ownProps.match.params.id,
                    id: order.id,
                    serialisedOptions,
                    opts: {
                      checkout: true,
                    },
                    index,
                  })
                : actions.upsizeOrder({
                    id: order.id,
                    venueId: ownProps.match.params.id,
                    serialisedOptions,
                    opts: {
                      checkout: true,
                    },
                    index,
                  }),
          }
        }),
      }),
    ),

    lifecycle({
      componentDidMount() {
        const {
          selectedOrders,
          checkoutOrder,
          checkoutMultiVendorOrder,
          venue,
          match,
        } = this.props
        const shouldCheckout = get(config, 'checkoutOnLoad', true)

        if (
          shouldCheckout &&
          selectedOrders &&
          selectedOrders.length &&
          !get(config, 'isMultiVendorOrderPage')
        ) {
          get(venue, 'isMultiVendor') && isMultiVendorEnv()
            ? checkoutMultiVendorOrder(match.params.id)
            : checkoutOrder(match.params.id)
        }

        if (get(config, 'isMultiVendorOrders')) {
          checkoutMultiVendorOrder({ shouldRemoveUnavailableItems: true })
        }
      },
    }),
  )

withOrderContainer.propTypes = {
  config: PT.shape({
    checkoutOnLoad: PT.bool,
    checkoutOnQuantityChange: PT.bool,
    shouldIncludeFees: PT.bool,
    shouldRemoveUnavailableItems: PT.bool,
    isMultiVendorOrderPage: PT.bool,
    isMultiVendorOrders: PT.bool,
  }),
}

export default withOrderContainer
