import styled from 'styled-components'
import { mobileFirstMedia as media } from 'utils/media'

export const AccountLayoutContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: initial;
  min-height: 800px;

  ${media.screen`
    flex-direction: row;
    justify-content: space-between;
  `};
`

export const AccountLeft = styled.div`
  width: 100%;
  margin-bottom: 40px;

  ${media.screen`
    width: 330px;
  `};
`

export const AccountRight = styled.div`
  width: 100%;
  margin-bottom: 40px;

  ${media.screen`
    width: calc(100% - 330px - 40px);
  `};
`
