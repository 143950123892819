import React from 'react'
import PropTypes from 'prop-types'

import { NumberPickerContainer, ActionButton, NumberDisplay } from './styled'

class NumberPicker extends React.Component {
  handleDecrease = () => {
    this.props.onChange(this.props.number - 1)
  };

  handleIncrease = () => {
    this.props.onChange(this.props.number + 1)
  };

  render () {
    const { number, min } = this.props
    return (
      <NumberPickerContainer>
        <ActionButton color='rgba(0, 0, 0, 0.2)' onClick={this.handleDecrease} disabled={number === min}>
          −
        </ActionButton>
        <NumberDisplay>{number}</NumberDisplay>
        <ActionButton color='orange' onClick={this.handleIncrease}>
          +
        </ActionButton>
      </NumberPickerContainer>
    )
  }
}

NumberPicker.defaultProps = {
  min: 1
}

NumberPicker.propTypes = {
  min: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  number: PropTypes.number.isRequired
}

export default NumberPicker
