import media from 'utils/media'
import styled from 'styled-components'

export default styled.section`
  background-color: #FFFFFF;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.05);
`

// Has shadow on desktop only
export const CardDt = styled.section`
  background-color: #FFFFFF;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.05);

  ${media.screen`
    box-shadow: none;
  `}
`
