import React from 'react'
import PropTypes from 'prop-types'
import { CheckboxContainer, CheckInput, Checkmark } from './styled'

class Checkbox extends React.PureComponent {
  state = {
    checked: this.props.checked || this.props.defaultChecked
  };

  static getDerivedStateFromProps (props, state) {
    if (props.onChange && props.checked !== state.checked) {
      return { checked: props.checked }
    }
    return null
  }

  handleCheck = event => {
    if (this.props.onChange) {
      return this.props.onChange(event)
    }
    this.setState({
      checked: event.target.checked
    })
  };

  render () {
    const { id, name, value, className, color, baseColor, outline, size, hovered } = this.props
    const { checked } = this.state

    const checkmarkColor = outline ? color : 'white'

    const highlighted = checked || hovered

    return (
      <CheckboxContainer className={className} size={size} color={color}>
        <CheckInput onChange={this.handleCheck} id={id} name={name} value={value} type='checkbox' checked={checked} />
        <Checkmark
          highlighted={highlighted}
          element='span'
          name='fas fa-check'
          checked={checked}
          baseColor={baseColor}
          color={checkmarkColor}
          height={size}
          width={size}
          size={Math.round(size * 0.5)}
        />
      </CheckboxContainer>
    )
  }
}

Checkbox.defaultProps = {
  size: 20,
  outline: true,
  baseColor: 'darkGrey'
}

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  className: PropTypes.string,
  outline: PropTypes.bool,
  color: PropTypes.string,
  baseColor: PropTypes.string,
  size: PropTypes.number,
  hovered: PropTypes.bool
}

export default Checkbox
