import React from 'react'
import {
  compose,
  withState,
  withHandlers,
  withProps,
  lifecycle,
} from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import events from 'const/analytics-events'
import { withAnalyticsHandler } from 'enhancers/withAnalytics'
import { updateSearchRoute } from 'pages/search/venues.js'
import {
  changeLocationInput,
  selectAutoComplete,
  fetchCurrentLocation,
  getIsLocationSelected,
} from './location'

const withLocationSearchBarContainer = compose(
  withRouter,
  withProps(() => ({ inputRef: React.createRef() })),
  withAnalyticsHandler,
  connect(
    state => ({
      ...state.location,
      isLocationSelected: getIsLocationSelected(state),
    }),
    {
      changeLocationInput,
      selectAutoComplete,
      fetchCurrentLocation,
      updateSearchRoute,
    },
  ),

  withState('hasFocus', 'setFocus', false),

  withHandlers(
    ({
      updateSearchRoute,
      selectAutoComplete,
      fetchCurrentLocation,
      changeLocationInput,
      setFocus,
      inputRef,
    }) => ({
      handleInputFocus: ({ hasFocus, placement, trackEvent }) => ev => {
        if (placement === 'hero') {
          trackEvent(events['HERO_CLICK_ADDRESS_INPUT'])
        }

        if (placement === 'subhero') {
          trackEvent(events['SUBHERO_CLICK_ADDRESS_INPUT'])
        }

        if (!hasFocus) setFocus(true)
      },
      handleInputBlur: ({
        inputValue,
        locationName,
        isCurrentLocation,
        hasFocus,
      }) => () => {
        if (hasFocus) setFocus(false)

        if (inputValue !== locationName)
          changeLocationInput(
            ...(isCurrentLocation
              ? ['Current Location', true]
              : [locationName]),
          )
      },
      handleInputChange: () => ev => changeLocationInput(ev.target.value),
      handleInputClear: ({ locationName, isCurrentLocation }) => () => {
        changeLocationInput(
          ...(isCurrentLocation ? ['Current Location', true] : [locationName]),
        )

        if (inputRef.current) inputRef.current.blur()
      },
      handleAutocompleteSelect: ({
        trackEvent,
        placement,
        locationName,
      }) => id => {
        if (placement === 'hero') {
          trackEvent({
            ...events['HERO_CLICK_LOCATION'],
            label: locationName,
          })
        }

        if (placement === 'subhero') {
          trackEvent({
            ...events['SUBHERO_CLICK_LOCATION'],
            label: locationName,
          })
        }

        selectAutoComplete(id)
      },
      handleUseCurrentLocation: ({ placement, trackEvent }) => () => {
        if (placement === 'hero') {
          trackEvent(events['HERO_CLICK_CURRENT_LOCATION'])
        }

        if (placement === 'subhero') {
          trackEvent(events['SUBHERO_CLICK_CURRENT_LOCATION'])
        }

        setFocus(false)

        fetchCurrentLocation()
      },
      handleSearch: ({ isLocationSelected, hasFocus }) => () => {
        if (isLocationSelected) {
          updateSearchRoute({ shouldRedir: true })
        } else if (!hasFocus) {
          setFocus(true)
        }
      },
    }),
  ),

  lifecycle({
    componentDidMount() {
      const {
        inputValue,
        locationName,
        changeLocationInput,
        isCurrentLocation,
      } = this.props

      if (inputValue !== locationName)
        changeLocationInput(
          ...(isCurrentLocation ? ['Current Location', true] : [locationName]),
        )
    },
  }),
)

export default withLocationSearchBarContainer
