export const STATUS_ACCEPTED = 'ACCEPTED'
export const STATUS_AWAITING_PAYMENT = 'AWAITING_PAYMENT'
export const STATUS_CREDIT = 'CREDIT'
export const STATUS_PAYMENT_FAILED = 'PAYMENT_FAILED'
export const STATUS_PAYMENT_RECEIVED = 'PAYMENT_RECEIVED'
export const STATUS_PENDING = 'PENDING'
export const STATUS_READY = 'READY'
export const STATUS_REJECTED_TIMEOUT = 'REJECTED-TIMEOUT'
export const STATUS_REJECTED_VENDOR = 'REJECTED-VENDOR'
export const STATUS_UNKNOWN = 'UNKNOWN'

export const STATUS_COLORS = {
  [STATUS_ACCEPTED]: 'secondary',
  [STATUS_AWAITING_PAYMENT]: 'primary',
  [STATUS_CREDIT]: 'secondary',
  [STATUS_PAYMENT_FAILED]: 'primary',
  [STATUS_PAYMENT_RECEIVED]: 'secondary',
  [STATUS_PENDING]: 'primary',
  [STATUS_READY]: 'secondary',
  [STATUS_REJECTED_TIMEOUT]: 'secondary',
  [STATUS_REJECTED_VENDOR]: 'primary',
  [STATUS_UNKNOWN]: 'primary',
}

export const STATUS_NAMES = {
  [STATUS_ACCEPTED]: 'Accepted',
  [STATUS_AWAITING_PAYMENT]: 'Awaiting Payment',
  [STATUS_CREDIT]: 'Credit',
  [STATUS_PAYMENT_FAILED]: 'Payment Failed',
  [STATUS_PAYMENT_RECEIVED]: 'Top-Up',
  [STATUS_PENDING]: 'Pending',
  [STATUS_READY]: 'Ready',
  [STATUS_REJECTED_TIMEOUT]: 'Timed out',
  [STATUS_REJECTED_VENDOR]: 'Rejected',
  [STATUS_UNKNOWN]: 'Unknown',
}

export const ORDER_TYPE_SALESORDER = 'salesorder'
export const PAYMENT_BTQ = 'BTQ'
export const PAYMENT_BTQ_DESC = 'Hey You Balance'
export const PAYMENT_PAYPAL_FUTURE = 'paypal_future'
export const PAYMENT_PAYPAL_FUTURE_DESC = 'Paypal Referenced Payment'
export const PAYMENT_PROMOTION = 'promotion_free_heyyou_credit_giv'
export const PAYMENT_CREDIT_CARD = 'creditcard'
export const PAYMENT_CARD_GENERIC = 'generic'
export const PAYMENT_CREDIT_CARD_DESC = 'Debit / Credit Card'
