import { connect } from 'react-redux'
import { compose } from 'recompose'
import { show, types } from 'store/modals'
import { getQuery } from './cuisine'

const withCuisineSearchBar = compose(
  connect(
    state => ({ inputValue: getQuery(state) }),
    dispatch => ({ handleInputFocus: () => dispatch(show(types.SEARCH)) })
  )
)

export default withCuisineSearchBar
